import React from "react";
import {
  MDBSelect,
  MDBDatePicker,
  MDBAlert,
  MDBTableHead,
  MDBTable,
  ToastContainer,
  MDBTableBody,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBBtnGroup,
} from "mdbreact";
import Loader from "../../Components/Loader";

import { API_CONFIG } from "../../Config/config-env.js";
import Pdf from "react-to-pdf";
import axios from "axios";
const ref = React.createRef();

class reportArqueo2 extends React.Component {
  state = {
    optionsT: [
      { value: 47, checked: true, text: "Envio a Domicilio" },
      { value: 85, text: "Recojo en Tienda" },
    ],
    options: [],
    userNameXF: "",
    userType: 47,
    SKU: "",
    rows1: [],
    noData: false,
    rows2: [],
    skuExists: "NO ENCONTRADO",
    skuState: "SIN INFORMACION",
    showResults: false,
    data: [],
    resultado_final: 0,
    to_date: new Date(),
    from_date: new Date(),
    fecha: "",
    dotacion: 500,
    compinfo: {},
    tipo_cambio: parseFloat(process.env.REACT_APP_TIPO_CAMBIO),
    facturas_finalizadas: 0,
    facturas_pendientes: 0,
    facturas_monto_finalizadas: 0,
    facturas_monto_pendientes: 0,

    facturas_monto_total: 0,
    metodos: [
      {
        text: "EFECTIVO",
        value: "EFECTIVO",
        total: 0,
        monto_total: 0,
        retiro: 0,
        tipo: "E",
      },
      {
        text: "EFECTIVO ME",
        value: "EFECTIVO ME",
        total: 0,
        monto_total: 0,
        retiro: 0,
        tipo: "E",
      },

      {
        text: "TARJETA ATC",
        value: "TARJETA ATC",
        total: 0,
        monto_total: 0,
        retiro: 0,
        tipo: "T",
      },
      {
        text: "TARJETA LINKSER",
        value: "TARJETA LINKSER",
        total: 0,
        monto_total: 0,
        retiro: 0,
        tipo: "T",
      },
      {
        text: "QR RED ENLACE",
        value: "QR RED ENLACE",
        total: 0,
        monto_total: 0,
        retiro: 0,
        tipo: "C",
      },
      {
        text: "LINEA DE CREDITO IISA",
        value: "LINEA DE CREDITO IISA",
        total: 0,
        monto_total: 0,
        retiro: 0,
        tipo: "C",
      },
      {
        text: "TODOTIX",
        value: "TODOTIX",
        total: 0,
        monto_total: 0,
        retiro: 0,
        tipo: "C",
      },
      {
        text: "CONTRAENTREGA",
        value: "CONTRAENTREGA",
        total: 0,
        monto_total: 0,
        retiro: 0,
        tipo: "C",
      },
    ],
    fact_fin: 110,
    fact_pend: 0,
    fact_tot: 110,
    fact_nulas: 0,
    medios_efec: 110,
    medios_qr: 0,
    medios_atc: 0,
    fact_dely: 0,
    fact_todotix: 0,
    fact_iisa: 0,
    fact_total: 110,
    total_tarj_atc: 0,
    total_tarj_link: 0,
    conv_qr_red_enlace: 0,
    conv_ret_qr_red_enlace: 0,
    cuad_ret_efec: 0,
    cuad_ret_usd: 0,
    cuad_ret_dot: 0,
    conv_todotix: 0,
    conv_contraentrega: 0,
    conv_ssa: 0,

    conv_ret_todotix: 0,
    conv_ret_contraentrega: 0,
    conv_ret_issa: 0,

    retiro_tarj_link: 0,
    retiro_tarj_atc: 0,
  };

  componentDidMount = () => {
    this.LoadSections(47);

    let f = new Date();
    let fecha =
      (f.getDate() < 10 ? "0" + f.getDate() : f.getDate()) +
      "/" +
      (f.getMonth() + 1 < 10 ? "0" + (f.getMonth() + 1) : f.getMonth() + 1) +
      "/" +
      (f.getFullYear() + "");

    var hora = f.getHours();
    var minuto = f.getMinutes() < 10 ? "0" + f.getMinutes() : f.getMinutes();
    var segundo = f.getSeconds() < 10 ? "0" + f.getSeconds() : f.getSeconds();

    if (hora > 12) {
      hora = hora - 12 + ":" + minuto + " PM";
    } else if (hora === 0) {
      hora = "12" + ":" + minuto + ":" + segundo + "AM";
    } else if (hora === 12) {
      hora = "12" + ":" + minuto + ":" + segundo + "PM";
    } else {
      hora = hora + ":" + minuto + ":" + segundo + "AM";
    }

    this.setState({ fecha: fecha + " " + hora });
  };

  generateReport = async () => {
    let params = new URLSearchParams(this.props.location.search);
    let token = params.get("token");
    this.setState({ isExecuting: true, showResults: false });

    let url = API_CONFIG.xflowurl + "/components/get-component-report";

    var f = new Date(this.state.from_date);
    var fecha =
      (f.getDate() < 10 ? "0" + f.getDate() : f.getDate()) +
      "/" +
      (f.getMonth() + 1 < 10 ? "0" + (f.getMonth() + 1) : f.getMonth() + 1) +
      "/" +
      (f.getFullYear() + "");
    var from_date = new Date(
      f.getFullYear(),
      f.getMonth(),
      f.getDate(),
      0,
      0,
      0
    );

    let datos = {
      components: this.state.userNameXF,
      from: from_date,
      usuario: this.state.userNameXF,
    };

    let components = this.state.compinfo.data;

    for (let i in components) {
      let component = components[i];
      let header = [];
      for (let j in component.values.options) {
        let obj = component.values.options[j];
        header.push(obj);
      }
      component["header"] = header;
    }
    // console.log("-+--------");
    // console.log(components);

    let filterDefinition = [
      {
        name: "usuario",
        type: "text",
        value: this.state.userNameXF,
      },
      {
        name: "from",
        type: "date",
        value: from_date,
      },
    ];
    /*
    if (this.state.userNameXF != "0") {
      console.log("hhay user");
      filterDefinition.push({
        name: "USERNAME",
        type: "input",
        value: this.state.userNameXF === "0" ? null : this.state.userNameXF,
      });
    }*/

    datos = {
      components: components,
      filterDefinition: filterDefinition,
      filters: {
        from: from_date,
        usuario: this.state.userNameXF,
      },
    };

    //console.log(datos);
    await axios({
      url: url,
      headers: {
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      method: "post",
      data: datos,
    })
      .then((response) => response.data)
      .then((data) => {
        //console.log(data.results[0]);
        if (data.status) {
          const metodos = [
            {
              text: "EFECTIVO",
              value: "EFECTIVO",
              total: 0,
              monto_total: 0,
              retiro: 0,
              tipo: "E",
            },
            {
              text: "EFECTIVO ME",
              value: "EFECTIVO ME",
              total: 0,
              monto_total: 0,
              retiro: 0,
              tipo: "E",
            },

            {
              text: "TARJETA ATC",
              value: "TARJETA ATC",
              total: 0,
              monto_total: 0,
              retiro: 0,
              tipo: "T",
            },
            {
              text: "TARJETA LINKSER",
              value: "TARJETA LINKSER",
              total: 0,
              monto_total: 0,
              retiro: 0,
              tipo: "T",
            },
            {
              text: "QR RED ENLACE",
              value: "QR RED ENLACE",
              total: 0,
              monto_total: 0,
              retiro: 0,
              tipo: "C",
            },
            {
              text: "LINEA DE CREDITO IISA",
              value: "LINEA DE CREDITO IISA",
              total: 0,
              monto_total: 0,
              retiro: 0,
              tipo: "C",
            },
            {
              text: "TODOTIX",
              value: "TODOTIX",
              total: 0,
              monto_total: 0,
              retiro: 0,
              tipo: "C",
            },
            {
              text: "CONTRAENTREGA",
              value: "CONTRAENTREGA",
              total: 0,
              monto_total: 0,
              retiro: 0,
              tipo: "C",
            },
          ];

          let orders = data.results[0];
          var facturas_finalizadas = 0;
          var facturas_pendientes = 0;
          var facturas_monto_total = 0;
          var facturas_monto_finalizadas = 0;
          var facturas_monto_pendientes = 0;
          for (let i in orders) {
            let order = orders[i];

            if (order.metodo_pago != "CONTRAENTREGA") {
              facturas_finalizadas++;
              facturas_monto_finalizadas =
                facturas_monto_finalizadas + +order.MONTO + order.MONTO2;
            } else {
              facturas_pendientes++;
              facturas_monto_pendientes =
                facturas_monto_pendientes + +order.MONTO + order.MONTO2;
            }
            facturas_monto_total =
              facturas_monto_total + order.MONTO + order.MONTO2;
            let pos = this.state.metodos.findIndex(
              (item) => item.value === order.METODO_PAGO
            );
            if (pos >= 0) {
              metodos[pos].monto_total = metodos[pos].monto_total + order.MONTO;
              metodos[pos].total = metodos[pos].total + 1;
            }
          }

          metodos[0].retiro = parseFloat(this.state.cuad_ret_efec); //EFECTIVO
          metodos[1].retiro = parseFloat(this.state.cuad_ret_usd); //EFECTIVO ME
          metodos[2].retiro = parseFloat(this.state.retiro_tarj_atc); //TARJETA ATC
          metodos[3].retiro = parseFloat(this.state.retiro_tarj_link); //TARJETA LINKSER
          metodos[4].retiro = parseFloat(this.state.conv_ret_qr_red_enlace); //QR RED ENLACE
          metodos[5].retiro = parseFloat(this.state.conv_ret_issa); //LINEA DE CREDITO IISA
          metodos[6].retiro = parseFloat(this.state.conv_ret_todotix); //TODOTIX
          metodos[7].retiro = parseFloat(this.state.conv_ret_contraentrega); //CONTRAENTREGA

          metodos[0].resultado =
            metodos[0].monto_total +
            parseFloat(this.state.dotacion) -
            (metodos[0].retiro + parseFloat(this.state.cuad_ret_dot));
          metodos[1].resultado =
            metodos[1].monto_total - metodos[1].retiro * this.state.tipo_cambio;
          metodos[2].resultado = metodos[2].monto_total - metodos[2].retiro;
          metodos[3].resultado = metodos[3].monto_total - metodos[3].retiro;
          metodos[4].resultado = metodos[4].monto_total - metodos[4].retiro;
          metodos[5].resultado = metodos[5].monto_total - metodos[5].retiro;
          metodos[6].resultado = metodos[6].monto_total - metodos[6].retiro;
          metodos[7].resultado = metodos[7].monto_total - metodos[7].retiro;

          //    console.log("metodos", metodos);
          var resultado_final = 0;
          for (let i in metodos) {
            let metodo = metodos[i];
            resultado_final = resultado_final + metodo.resultado;
          }

          resultado_final = Math.round(resultado_final * 100) / 100;

          //          console.log("resultado_final:", resultado_final);

          this.setState({
            ...this.state,
            showResults: true,
            noData: false,
            metodos,
            facturas_finalizadas,
            facturas_pendientes,
            facturas_monto_total,
            facturas_monto_finalizadas,
            facturas_monto_pendientes,
            resultado_final,
          });
          this.setState({ isExecuting: false });
        } else {
          this.setState({
            isExecuting: false,
            noData: true,
          });
        }
      });
  };

  LoadSections = async (userType) => {
    let params = new URLSearchParams(this.props.location.search);
    let token = params.get("token");
    this.setState({ isExecuting: true });

    await axios({
      headers: {
        crossdomain: true,
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      url: API_CONFIG.xflowurl + "/engine-users/decrypt-token",
      //url : "http://localhost:4042/api/v1"+"/engine-users/decrypt-token",
      method: "post",
    })
      .then((response) => response.data)
      .then((data) => {
        console.log("data", data);
        if (data.status) {
          this.setState({
            ...this.state,
            userNameXF: data.token.USERNAME,
            username: data.token.USERNAME,
            organization: data.token.ORGANIZATIONCODE,
          });
        }
      });

    await axios({
      url: API_CONFIG.xflowurl + "/components/get-single-by-code",
      data: { component: "dashboards", code: "CIERRE_USUARIO" },
      headers: {
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      method: "post",
    })
      .then((response) => response.data)
      .then((data) => {
        let compinfo = data.data.DATA;
        console.log("compinfo", compinfo);
        this.setState({ compinfo: compinfo });
      });

    let url = API_CONFIG.xflowurl + "/users/get-all";

    await axios({
      url: url,
      headers: {
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      method: "post",
    })
      .then((response) => response.data)
      .then((data) => {
        if (data.result.length > 0) {
          var objSections = [];

          objSections.push({
            text: "TODOS",
            value: "0",
            checked: true,
          });

          for (var key in data.result) {
            let dataObject = {
              value: data.result[key].USERNAME,
              text: data.result[key].USERNAME,
            };
            objSections.push(dataObject);
          }

          this.setState({ options: objSections });

          this.setState({ isExecuting: false });
        } else {
          this.setState({ isExecuting: false });
        }
      });
  };

  selectHandlerT = (event) => {
    if (event[0]) {
      this.setState({ ...this.state, userType: event[0], userNameXF: "" });
      this.LoadSections(event[0]);
    }
  };

  selectHandler = (event) => {
    this.setState({ ...this.state, userNameXF: event[0] });
  };

  changeHandler = (event) => {
    //console.log(event.target.value);
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };
  getPickerValueTo = (value) => {
    this.setState({ to_date: value });
  };
  getPickerValueFrom = (value) => {
    this.setState({ from_date: value });
  };

  render() {
    return (
      <MDBContainer
        fluid
        className="justify-content-left text-left "
        style={{ marginLeft: "0" }}
      >
        <Loader idLoading={this.state.isExecuting} />

        {/*
        <MDBRow>
          <MDBCol sm="12" md="9" lg="4" className="text-left">
            <MDBSelect
              className="is-invalid"
              key="store"
              id="store"
              color="primary"
              options={this.state.optionsT}
              getValue={this.selectHandlerT}
              selected="Elija el tipo de Envio"
              label="Tipo de Envio"
            />
          </MDBCol>
        </MDBRow>
        */}

        <MDBRow className="mt-3">
          <MDBCol sm="12" md="9" lg="4" className="text-left">
            <MDBInput
              id="userNameXF"
              key="userNameXF"
              name="userNameXF"
              value={this.state.userNameXF}
              readonly={true}
              size="sm"
              label="Usuario"
            />
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol sm="12" md="9" lg="4" className="text-left">
            <MDBDatePicker
              key="from"
              name="from"
              id="from"
              format="DD/MM/YYYY"
              value={this.state.from_date}
              cancelLabel="Cancelar"
              getValue={this.getPickerValueFrom}
            />
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol sm="12" md="6" lg="6" className="text-left">
            <MDBInput
              id="dotacion"
              key="dotacion"
              name="dotacion"
              value={this.state.dotacion}
              onChange={this.changeHandler}
              size="sm"
              label="Dotación"
            />
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol sm="12" md="6" lg="6" className="text-left">
            <MDBInput
              id="cuad_ret_dot"
              key="cuad_ret_dot"
              name="cuad_ret_dot"
              value={this.state.cuad_ret_dot}
              onChange={this.changeHandler}
              size="sm"
              label="Retiro dotación"
            />
          </MDBCol>
          <MDBCol sm="12" md="6" lg="6" className="text-left">
            <MDBInput
              id="cuad_ret_efec"
              key="cuad_ret_efec"
              name="cuad_ret_efec"
              value={this.state.cuad_ret_efec}
              onChange={this.changeHandler}
              size="sm"
              label="Retiro efectivo"
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12" md="6" lg="6" className="text-left">
            <MDBInput
              id="cuad_ret_usd"
              key="cuad_ret_usd"
              name="cuad_ret_usd"
              value={this.state.cuad_ret_usd}
              onChange={this.changeHandler}
              size="sm"
              label={"Retiro USD. (T/C " + this.state.tipo_cambio + ")"}
            />
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol sm="12" md="6" lg="6" className="text-left">
            <MDBInput
              id="retiro_tarj_atc"
              key="retiro_tarj_atc"
              name="retiro_tarj_atc"
              value={this.state.retiro_tarj_atc}
              onChange={this.changeHandler}
              size="sm"
              label="Retiro tarj. ATC"
            />
          </MDBCol>
          <MDBCol sm="12" md="6" lg="6" className="text-left">
            <MDBInput
              id="retiro_tarj_link"
              key="retiro_tarj_link"
              name="retiro_tarj_link"
              value={this.state.retiro_tarj_link}
              onChange={this.changeHandler}
              size="sm"
              label="Retiro tarj. Linkser"
            />
          </MDBCol>
        </MDBRow>

        <MDBRow className="my-3">
          <MDBCol sm="12" md="12" lg="12" className="text-left">
            <h6>CONVENIOS</h6>
          </MDBCol>
        </MDBRow>

        <MDBRow className="mt-2">
          <MDBCol sm="12" md="6" lg="6" className="text-left">
            <MDBInput
              id="conv_ret_qr_red_enlace"
              key="conv_ret_qr_red_enlace"
              name="conv_ret_qr_red_enlace"
              value={this.state.conv_ret_qr_red_enlace}
              onChange={this.changeHandler}
              size="sm"
              label="Retiro QR RED ENLACE"
            />
          </MDBCol>

          <MDBCol sm="12" md="6" lg="6" className="text-left">
            <MDBInput
              id="conv_ret_issa"
              key="conv_ret_issa"
              name="conv_ret_issa"
              value={this.state.conv_ret_issa}
              onChange={this.changeHandler}
              size="sm"
              label="Retiro LINEA DE CREDITO IISA"
            />
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol sm="12" md="6" lg="6" className="text-left">
            <MDBInput
              id="conv_ret_contraentrega"
              key="conv_ret_contraentrega"
              name="conv_ret_contraentrega"
              value={this.state.conv_ret_contraentrega}
              onChange={this.changeHandler}
              size="sm"
              label="Retiro CONTRAENTREGA"
            />
          </MDBCol>

          <MDBCol sm="12" md="6" lg="6" className="text-left">
            <MDBInput
              id="conv_ret_todotix"
              key="conv_ret_todotix"
              name="conv_ret_todotix"
              value={this.state.conv_ret_todotix}
              onChange={this.changeHandler}
              size="sm"
              label="Retiro TODOTIX"
            />
          </MDBCol>
        </MDBRow>

        {/**
        <MDBRow>
          <MDBCol sm="12" md="9" lg="4" className="text-left">
            <MDBDatePicker
              key="to"
              name="to"
              id="to"
              format="DD/MM/YYYY"
              value={this.state.to_date}
              cancelLabel="Cancelar"
              getValue={this.getPickerValueTo}
            />
          </MDBCol>
        </MDBRow>
 */}
        <MDBRow>
          <MDBCol
            sm="12"
            md="12"
            lg="12"
            className="text-right justify-content-right"
          >
            <MDBBtn
              href="#reportResult"
              key="find"
              onClick={() => {
                this.generateReport();
              }}
              size="sm"
              color="success"
              className="text-capitalize"
            >
              Generar Reporte
            </MDBBtn>
          </MDBCol>
        </MDBRow>

        {this.state.noData ? (
          <>
            {" "}
            <br />
            <MDBAlert color="warning" dismiss>
              No se encontraron datos para el periodo seleccionado
            </MDBAlert>
          </>
        ) : (
          ""
        )}
        {this.state.showResults ? (
          <>
            <br />
            <hr />
            <MDBRow>
              <MDBCol
                sm="12"
                md="12"
                lg="12"
                xl="12"
                className="text-left justify-content-left"
              >
                <div
                  style={{
                    width: "500px",
                  }}
                  className="justify-content-left text-left"
                >
                  <Pdf
                    targetRef={ref}
                    filename={"Arqueo" + this.state.userNameXF + ".pdf"}
                    scale={0.87}
                  >
                    {({ toPdf }) => (
                      <MDBBtn
                        key="find"
                        size="sm"
                        color="success"
                        onClick={toPdf}
                      >
                        Exportar a Pdf
                      </MDBBtn>
                    )}
                  </Pdf>
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol
                sm="12"
                md="12"
                lg="12"
                xl="12" 
              >
                <div ref={ref} className="pt-5 pl-5">
                  <table
                  border="1"
                    id="table1"
                    small
                    responsive
                    style={{
                      width: "500px",
                     
                    }}
                    className="tableBlue"
                    bordered
                  >
                    <tr>
                      <th className="text-center" colSpan="4">
                        <h5>
                          <strong> COMPROBANTE CIERRE DE USUARIO</strong>{" "}
                        </h5>
                      </th>
                    </tr>

                    <tr>
                      <td> Fecha</td>
                      <td colSpan="2">{this.state.fecha}</td>
                    </tr>

                    <tr>
                      <td> Usuario</td>
                      <td colSpan="2">{this.state.userNameXF}</td>
                    </tr>
                    <tr>
                      <td> Dotación</td>

                      <td colSpan="2">
                        {" "}
                        {parseFloat(this.state.dotacion).toFixed(2)}{" "}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <h6>
                          <strong> COMPROBANTES EMITIDOS - VENTAS</strong>{" "}
                        </h6>
                      </td>
                    </tr>

                    <tr>
                      <td> FACTURAS - FINALIZADAS </td>
                      <td className="text-right">
                        {" "}
                        {this.state.facturas_monto_finalizadas.toFixed(2)}
                      </td>
                      <td className="text-right">
                        {" "}
                        {this.state.facturas_finalizadas}
                      </td>
                    </tr>
                    <tr>
                      <td> FACTURAS - PENDIENTES DE COBRO </td>
                      <td className="text-right">
                        {" "}
                        {this.state.facturas_monto_pendientes.toFixed(2)}
                      </td>
                      <td className="text-right">
                        {" "}
                        {this.state.facturas_pendientes}
                      </td>
                    </tr>
                    <tr>
                      <td> TOTAL </td>
                      <td className="text-right">
                        {" "}
                        {this.state.facturas_monto_total.toFixed(2)}
                      </td>
                      <td className="text-right">
                        {this.state.facturas_finalizadas +
                          this.state.facturas_pendientes}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan="3">
                        <h6>
                          <strong> COMPROBANTES EMITIDOS - VENTAS NULAS</strong>{" "}
                        </h6>
                      </td>
                    </tr>

                    {this.state.metodos.map(
                      (m) =>
                        m.total > 0 && (
                          <tr>
                            <td> {m.value}</td>
                            <td className="text-right"> {m.monto_total}</td>
                            <td className="text-right"> {m.total}</td>
                          </tr>
                        )
                    )}

                    <tr>
                      <td> TOTAL </td>
                      <td className="text-right">
                        {" "}
                        {this.state.facturas_monto_total.toFixed(2)}
                      </td>
                      <td className="text-right">
                        {" "}
                        {this.state.facturas_finalizadas +
                          this.state.facturas_pendientes}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan="3">
                        <h6>
                          <strong> CUADRATURA</strong>{" "}
                        </h6>
                      </td>
                    </tr>
                    {this.state.metodos[0].monto_total > 0 && (
                      <>
                        <tr>
                          <td> TOTAL EFECTIVO </td>
                          <td className="text-right">
                            {" "}
                            {(
                              this.state.metodos[0].monto_total +
                              parseFloat(this.state.dotacion)
                            ).toFixed(2)}
                          </td>
                          <td className="text-right">
                            {" "}
                            {this.state.metodos[0].total}
                          </td>
                        </tr>
                        <tr>
                          <td> RETIRO DOTACION</td>
                          <td className="text-right">
                            {" "}
                            -{parseFloat(this.state.cuad_ret_dot).toFixed(2)}
                          </td>
                          <td className="text-right"> 0</td>
                        </tr>
                        <tr>
                          <td> RETIRO EFECTIVO</td>
                          <td className="text-right">
                            {" "}
                            -{parseFloat(this.state.cuad_ret_efec).toFixed(2)}
                          </td>
                          <td className="text-right"> 0</td>
                        </tr>
                        <tr>
                          <td>RETIRO USD. (T/C {this.state.tipo_cambio} )</td>
                          <td className="text-right">
                            -
                            {(
                              parseFloat(this.state.cuad_ret_usd) *
                              this.state.tipo_cambio
                            ).toFixed(2)}
                          </td>
                          <td className="text-right"> 0</td>
                        </tr>
                        <tr>
                          <td> RESULTADO EFECTIVO </td>
                          <td className="text-right">
                            {(
                              this.state.metodos[0].monto_total +
                              parseFloat(this.state.dotacion) -
                              (parseFloat(this.state.cuad_ret_dot) +
                                parseFloat(this.state.cuad_ret_efec) +
                                parseFloat(this.state.cuad_ret_usd) *
                                  this.state.tipo_cambio)
                            ).toFixed(2)}
                          </td>
                          <td className="text-right"> 0</td>
                        </tr>
                      </>
                    )}

                    {this.state.metodos.map(
                      (m) =>
                        m.total > 0 &&
                        m.tipo === "T" && (
                          <>
                            <tr>
                              <td> TOTAL {m.value}</td>
                              <td className="text-right">
                                {" "}
                                {parseFloat(m.monto_total).toFixed(2)}
                              </td>
                              <td className="text-right"> {m.total}</td>
                            </tr>
                            <tr>
                              <td> RETIRO {m.value} </td>
                              <td className="text-right">
                                {" "}
                                {parseFloat(m.retiro).toFixed(2)}
                              </td>
                              <td className="text-right"> 0</td>
                            </tr>
                            <tr>
                              <td> RESULTADO {m.value}</td>
                              <td className="text-right">
                                {" "}
                                {parseFloat(m.monto_total - m.retiro).toFixed(
                                  2
                                )}
                              </td>
                              <td className="text-right"> 0</td>
                            </tr>
                          </>
                        )
                    )}

                    <tr>
                      <td colSpan="3">
                        <h6>
                          <strong> CONVENIOS</strong>{" "}
                        </h6>
                      </td>
                    </tr>
                    {this.state.metodos.map(
                      (m) =>
                        m.total > 0 &&
                        m.tipo === "C" && (
                          <>
                            <tr>
                              <td> TOTAL {m.value}</td>
                              <td className="text-right">
                                {" "}
                                {parseFloat(m.monto_total).toFixed(2)}
                              </td>
                              <td className="text-right"> {m.total}</td>
                            </tr>
                            <tr>
                              <td> RETIRO {m.value} </td>
                              <td className="text-right">
                                {" "}
                                {parseFloat(m.retiro).toFixed(2)}
                              </td>
                              <td className="text-right"> 0</td>
                            </tr>
                            <tr>
                              <td> RESULTADO {m.value}</td>
                              <td className="text-right">
                                {" "}
                                {parseFloat(m.monto_total - m.retiro).toFixed(
                                  2
                                )}
                              </td>
                              <td className="text-right"> 0</td>
                            </tr>
                            <tr>
                              <td colSpan="3"> </td>
                            </tr>
                          </>
                        )
                    )}

                    <tr>
                      <td> SOBRANTE</td>
                      <td colSpan="2" className="text-right">
                        {" "}
                        {this.state.resultado_final < 0
                          ? this.state.resultado_final.toFixed(2)
                          : 0}
                      </td>
                    </tr>
                    <tr>
                      <td> FALTANTE</td>
                      <td colSpan="2" className="text-right">
                        {" "}
                        {this.state.resultado_final > 0
                          ? this.state.resultado_final.toFixed(2)
                          : 0}
                      </td>
                    </tr>

                    <tr>
                      <td> FACTURAS - PENDIENTES DE COBRO</td>
                      <td colSpan="2" className="text-right">
                        {" "}
                        {this.state.facturas_monto_pendientes.toFixed(2)}
                      </td>
                    </tr>

                    <tr>
                      <td height="80px" colSpan="3">
                        <h6>
                          <strong> Observaciones</strong>{" "}
                        </h6>
                      </td>
                    </tr>

                    <tr style={{ marginTop: 10 }}>
                      <th height="8¿50px" className="text-center" colSpan="3">
                        <h6 style={{ paddingTop: 30 }}>
                          <strong> Firma Usuario</strong>{" "}
                        </h6>
                      </th>
                    </tr>

                    <tr style={{ marginTop: 10 }}>
                      <th height="50px" className="text-center" colSpan="3">
                        <h6 style={{ paddingTop: 30 }}>
                          <strong> Firma Supervisor</strong>{" "}
                        </h6>
                      </th>
                    </tr>
                  </table>
                </div>
              </MDBCol>
            </MDBRow>
          </>
        ) : (
          ""
        )}

        <ToastContainer
          hideProgressBar={true}
          newestOnTop={false}
          position={"bottom-right"}
          autoClose={3000}
        />
      </MDBContainer>
    );
  }
}
export default reportArqueo2;
