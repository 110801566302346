import React from "react";
import {
  MDBSelect,
  MDBNotification,
  MDBInputGroup,
  MDBDatePicker,
  toast,
  MDBTypography,
  MDBFormInline,
  MDBBadge,
  MDBAnimation,
  ToastContainer,
  MDBIcon,
  MDBDataTable,
  MDBAlert,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBBtnGroup,
} from "mdbreact";
import Loader from "../../Components/Loader";
import { API_CONFIG, casaideas_credentials } from "../../Config/config-env.js";

import { parseCurrencyToNumber } from "../../Config/functions";
import axios from "axios";
//para registrar entrega
class externalForm02 extends React.Component {
  state = {
    id_producto: -1,
    mensaje: "",
    accion: "",
    executedTask: false,
    isDeleted: false,
    productQty: 1,
    productName: "",
    formButtons: {
      type: "",
      buttonsList: [],
    },
    SKUcode: "3212567000066",
    productPrice: 0,
    existingPreviousOrder: false,
    isExecuting: false,
    processing: "Procesando",
    costo_envio: 0,
    monto_real: "",
    monto_2: "",
    metodo_pago: "",
    tipo_documento: "",
    metodo_pago_2: "",
    tarjeta_1: "",
    tarjeta_2: "",
    TRX: "",
    from_date: new Date(),
    descuento: 0,
    sub_total: 0,
    tipos_documento: [
      {
        text: "Cédula de Identidad",
        value: "Cédula de Identidad",
        codigo: "1",
      },
      {
        text: "Cédula de Identidad de Extranjero",
        value: "Cédula de Identidad de Extranjero",
        codigo: "2",
      },
      { text: "Pasaporte", value: "Pasaporte", codigo: "3" },
      {
        text: "Otro Documento de Identidad",
        value: "Otro Documento de Identidad",
        codigo: "4",
      },
      {
        text: "Número de Identificación Tributaria",
        value: "Número de Identificación Tributaria",
        codigo: "5",
      },
    ],
    observation: "",
    motivo_no_entrega: "",
    invoice_status: "PENDIENTE",
    invoice_response: "",
    motivos: [
      { text: "NO SE ENCUENTRA EN CASA", value: "NO SE ENCUENTRA EN CASA" },
      { text: "NO RESPONDE EL TELEFONO", value: "NO RESPONDE EL TELEFONO" },
      {
        text: "CLIENTE PIDIO RE-PROGRAMACION  PARA OTRO DIA",
        value: "CLIENTE PIDIO RE-PROGRAMACION  PARA OTRO DIA",
      },
      {
        text: "ERROR EN ENTREGA (PEDIDO NO CORRESPONDE AL CLIENTE)",
        value: "ERROR EN ENTREGA (PEDIDO NO CORRESPONDE AL CLIENTE)",
      },
      {
        text: "CLIENTE DETECTO FALLAS EN EL PRODUCTO SE RECHAZO LA ENTREGA",
        value: "CLIENTE DETECTO FALLAS EN EL PRODUCTO SE RECHAZO LA ENTREGA",
      },
      {
        text: "NO SE LLEGO  A TIEMPO ( FALLA MECANICA DE TRANSPORTE)",
        value: "NO SE LLEGO  A TIEMPO ( FALLA MECANICA DE TRANSPORTE)",
      },
      {
        text: "NO SE LLEGO A TIEMPO (MUCHA TRANCADERA)",
        value: "NO SE LLEGO A TIEMPO (MUCHA TRANCADERA)",
      },
      {
        text: "NO SE LLEGO A TIEMPO (DIRECCION DE ENTREGA LEJANA)",
        value: "NO SE LLEGO A TIEMPO (DIRECCION DE ENTREGA LEJANA)",
      },
      {
        text: "NO SE LLEGO A TIEMPO (DIRECCION DE ENTREGA INCORRECTA)",
        value: "NO SE LLEGO A TIEMPO (DIRECCION DE ENTREGA INCORRECTA)",
      },
    ],
    sendstore: "",
    metodos: [],
    metodos_orig: [
      { text: "EFECTIVO", value: "EFECTIVO", checked: false },
      { text: "EFECTIVO ME", value: "EFECTIVO ME", checked: false },

      { text: "TARJETA ATC", value: "TARJETA ATC", checked: false },
      { text: "TARJETA LINKSER", value: "TARJETA LINKSER", checked: false },
      { text: "QR RED ENLACE", value: "QR RED ENLACE", checked: false },
      {
        text: "LINEA DE CREDITO IISA",
        value: "LINEA DE CREDITO IISA",
        checked: false,
      },
      { text: "TODOTIX", value: "TODOTIX", checked: false },
      { text: "CONTRAENTREGA", value: "CONTRAENTREGA", checked: false },
    ],
    metodos2: [
      { text: "NINGUNO", value: "", checked: true },
      { text: "TARJETA ATC", value: "TARJETA ATC", checked: false },
      { text: "TARJETA LINKSER", value: "TARJETA LINKSER", checked: false },
      { text: "QR RED ENLACE", value: "QR RED ENLACE", checked: false },
      {
        text: "LINEA DE CREDITO IISA",
        value: "LINEA DE CREDITO IISA",
        checked: false,
      },
      { text: "TODOTIX", value: "TODOTIX", checked: false },
      { text: "CONTRAENTREGA", value: "CONTRAENTREGA", checked: false },
    ],

    orders: [],
    WO_Id: "",
    isLoadedTable: false,
    loadingData: true,
    modal4: false,
    modal33: false,
    order_id: "",
    orderName: "",
    orderPrice: "Bs.50",
    orderQty: "1",
    customerName: "",
    customerAdd: "",
    customerZone: "",
    productMaxQty: 0,
    disabledAdd: true,
    formButtons: [],
    numero_factura: "0",
    razon_social: "",
  };

  getPickerValueFrom = (value) => {
    this.setState({ from_date: value });
  };

  componentDidMount = () => {
    this.LoadTable();
  };

  changeHandler = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  selectHandler2 = (event) => {
    //console.log("dato"+event[0])
    if (event[0]) {
      this.setState({ ...this.state, metodo_pago_2: event[0] });
    } else {
      this.setState({ ...this.state, metodo_pago_2: "" });
    }

    if (event[0] == "") {
      this.setState({ ...this.state, metodo_pago_2: "", monto_2: "" });
    }
  };

  selectHandler1 = (event) => {
    if (event[0]) {
      this.setState({ ...this.state, metodo_pago: event[0] });
    } else {
      this.setState({ ...this.state, metodo_pago: "" });
    }
  };

  selectHandler4 = (event) => {
    if (event[0]) {
      this.setState({ ...this.state, tipos_documento: event[0] });
    } else {
      this.setState({ ...this.state, tipos_documento: "" });
    }
  };

  selectHandler3 = (event) => {
    if (event[0]) {
      this.setState({ ...this.state, motivo_no_entrega: event[0] });
    } else {
      this.setState({ ...this.state, motivo_no_entrega: "" });
    }
  };

  saveInoiceData = async (event) => {
    //console.log("change",this.state.payId);
    this.setState({ isExecuting: true });
    let url = API_CONFIG.xflowurl + "/engine-users/save-flow-field";
    await axios({
      url: url,
      method: "put",
      data: {
        WO_flowID: this.state.WO_Id,
        WO_TaskID: this.state.WO_TaskId,
        params: [
          { var: "numero_factura", value: this.state.numero_factura },
          { var: "TRX", value: this.state.TRX },
          { var: "razon_social", value: this.state.razon_social },
        ],
      },
      headers: {
        crossdomain: true,
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token,
      },
    })
      .then((response) => response.data)
      .then(async (data) => {
        console.log(data);
        if (data.status) {
          url =
            API_CONFIG.xflowurl + "/customcomponents/update/resumen_ordenes";
          await axios({
            url: url,
            method: "put",
            data: {
              ID_PEDIDO: this.state.order_id,
              TRX: this.state.TRX,
              NROFACTURA: this.state.numero_factura,
              partial: true,
            },
            headers: {
              crossdomain: true,
              "Access-Control-Allow-Origin": "*",
              origin: "x-requested-with",
              "Access-Control-Allow-Headers":
                "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
              "Content-Type": "application/json",
              Authorization: "Bearer " + this.state.token,
            },
          })
            .then((response) => response.data)
            .then((data) => {
              console.log(data);
              if (data.status) {
                toast.success("OK, datos guardados correctamente", {
                  closeButton: true,
                });
              } else {
                toast.error("NOK, error guardando datos, intente nuevamente", {
                  closeButton: true,
                });
              }
            });
        } else {
          toast.error("NOK, error guardando datos, intente nuevamente", {
            closeButton: true,
          });
        }
      });

    this.setState({ isExecuting: false });
  };

  generateInvoice = async (bypass) => {
    let numero_factura, invoice_response, invoice_status, TRX;
    let url = API_CONFIG.xflowurl + "/engine-users/save-flow-field";
    let datos_pedido = "";
    let datos_factura = "";
    let pdf_factura = "";
    console.log("this", this.state);
    if (bypass) {
      this.setState({ isExecuting: true });

      numero_factura = "0";
      invoice_response = "BYPASS APLICADO";
      invoice_status = "BYPASS";
      TRX = "0";
      console.log("bypass");

      this.setState({
        invoice_response: invoice_response,
        numero_factura: numero_factura,
        invoice_status: invoice_status,
        TRX: TRX,
      });

      await axios({
        url: url,
        method: "put",
        data: {
          WO_flowID: this.state.WO_Id,
          WO_TaskID: this.state.WO_TaskId,
          params: [
            { var: "numero_factura", value: numero_factura },
            { var: "invoice_response", value: invoice_response },
            { var: "invoice_status", value: invoice_status },
            { var: "TRX", value: TRX },
            { var: "invoice_user", value: this.state.invoice_user },
            { var: "invoice_date", value: this.state.from_date },
 
          ],
        },
        headers: {
          crossdomain: true,
          "Access-Control-Allow-Origin": "*",
          origin: "x-requested-with",
          "Access-Control-Allow-Headers":
            "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.token,
        },
      });

      this.setState({ isExecuting: false, modal33: false });
      toast.success("BYPASS APLICADO", {
        closeButton: true,
      });
    } else {
      let responseValidation = true;

      if (this.state.metodo_pago === "TARJETA ATC") {
        if (this.state.tarjeta_1.length != 4) {
          responseValidation = false;
          toast.warning("Debe completar los primeros 4 dígitos de la tarjeta", {
            closeButton: true,
          });
        }
        if (this.state.tarjeta_2.length != 4) {
          responseValidation = false;
          toast.warning("Debe completar los últimos 4 dígitos de la tarjeta", {
            closeButton: true,
          });
        }
      }

      if (this.state.razon_social === "") {
        responseValidation = false;
        toast.warning("Debe completar la razón social", { closeButton: true });
      }

      if (this.state.nit === "") {
        responseValidation = false;
        toast.warning("Debe completar la Nit/Carnet de Identidad", {
          closeButton: true,
        });
      }

      if (this.state.tipo_documento === "") {
        responseValidation = false;
        toast.warning("Debe completar el Tipo de Documento", {
          closeButton: true,
        });
      }
      let monto_total = 0;
      if (responseValidation) {
        this.setState({ isExecuting: true });
        let listaProductos = [];
        let id_pedido = this.state.order_id;

        this.state.orders.map((sku, index) => {
          monto_total += parseCurrencyToNumber(sku.productPrice);

          listaProductos.push({
            linea: 1,
            codProducto: sku.productSKU,
            descripcion: sku.productName,
            precioLista: parseCurrencyToNumber(sku.productPrice),
            precioFinal: parseCurrencyToNumber(sku.productPrice),
            cantidad: sku.productQty,
            codDescuento: "",
            montoDescuento: 0,
            textoRefDescuento: "BOL",
            codBodega: this.state.storeCodeI,
            nroPedidoRef: id_pedido,
          });
        });
        monto_total = parseFloat(monto_total.toFixed(1));
        console.log("monto_total", monto_total);
        let InvoiceData = {
          montoPedido: monto_total,
          componentcode: "facturaXflow",
          id_pedido: id_pedido,
          nit: this.state.nit,
          nombres: "", //this.state.customerName,
          apellidoPat: this.state.razon_social,
          apellidoMat: "",
          email: this.state.email,
          telefono: this.state.telefono.replace("+591 ", ""),
          direccion: this.state.direccion_1,
          referencia: this.state.direccion_2,
          ciudad: this.state.ciudad,
          tarjeta_1: this.state.tarjeta_1,
          tarjeta_2: this.state.tarjeta_2,
          listaProductos: listaProductos,
          storeCodeI: this.state.storeCodeI,
          metodo_pago: this.state.metodo_pago,
          fecha_pago: this.state.from_date,
        };
        let response = {};

        await axios({
          headers: {
            "Access-Control-Allow-Origin": "*",
            origin: "x-requested-with",
            "Access-Control-Allow-Headers":
              "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.state.token,
          },
          url: API_CONFIG.xflowurl + "/engine-users/execute-api",
          //url : "http://localhost:4042/api/v1"+"/engine-users/execute-task",
          data: InvoiceData,
          method: "post",
        })
          .then((response) => response.data)
          .then((data) => {
            response = data;
          });
        console.log("response", response);

        if (!response.status) {
          invoice_response = "Error en la facturación";
          invoice_status = "EJECUTADO CON ERROR";
          numero_factura = "-1";
          TRX = "0";
          toast.error(invoice_response, {
            closeButton: true,
          });

          datos_pedido = JSON.stringify(response.data);
          datos_factura = JSON.stringify(response.data2);

          if (!response.resp1.status) {
            console.log("ERROR API Creacion de pedido", response.resp1);
            invoice_response = response.resp1.data;
            this.setState({
              datos_enviados:
                "....DATOS PEDIDO:..." +
                JSON.stringify(response.data) +
                "....DATOS FACTURA:..." +
                JSON.stringify(response.data2),
            });
            try {
              invoice_response =
                response.resp1.data.ErrorInfoExt.errorMsg +
                ".Código:" +
                response.resp1.data.ErrorInfoExt.errorCode;
            } catch (e) {
              console.log("error por default");
              invoice_response = response.resp1.message;
            }
          } else {
            if (!response.resp2.status) {
              console.log(
                "Error API Facturacion",
                response.resp2.data.ErrorInfoExt
              );
              try {
                invoice_response =
                  response.resp2.data.ErrorInfoExt.errorMsg +
                  ".Código:" +
                  response.resp2.data.ErrorInfoExt.errorCode;
              } catch (e) {
                console.log("error por default");
                invoice_response = response.resp2.message;
              }
            }
          }
        } else {
          console.log("OK");
          invoice_response = "Factura generada correctamente";
          invoice_status = "EJECUTADO CORRECTAMENTE";

          numero_factura = response.resp2.RespCrearVentaDesdePedidoDatos.numDoc;
          TRX = response.resp2.RespCrearVentaDesdePedidoDatos.nroTrx;
          pdf_factura = response.resp2.RespCrearVentaDesdePedidoDatos.pdfbase64;
          datos_pedido = JSON.stringify(response.data);
          datos_factura = JSON.stringify(response.data2);

          toast.success("EJECUTADO CORRECTAMENTE", {
            closeButton: true,
          });
        }
        await axios({
          url: url,
          method: "put",
          data: {
            WO_flowID: this.state.WO_Id,
            WO_TaskID: this.state.WO_TaskId,
            params: [
              { var: "numero_factura", value: numero_factura },
              { var: "invoice_response", value: invoice_response },
              { var: "invoice_status", value: invoice_status },
              { var: "TRX", value: TRX },
              { var: "pdf_factura", value: pdf_factura },
              { var: "datos_pedido", value: datos_pedido },
              { var: "datos_factura", value: datos_factura },
              { var: "invoice_user", value: this.state.invoice_user },
              { var: "invoice_date", value: this.state.from_date },
            ],
          },
          headers: {
            crossdomain: true,
            "Access-Control-Allow-Origin": "*",
            origin: "x-requested-with",
            "Access-Control-Allow-Headers":
              "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.state.token,
          },
        });

        this.setState({
          numero_factura: numero_factura,
          invoice_response: invoice_response,
          invoice_status: invoice_status,
          TRX: TRX,
        });
        this.setState({ isExecuting: false, modal33: false });
      }
    }
  };

  LoadTable = async () => {
    let params = new URLSearchParams(this.props.location.search);
    let token = params.get("token");
    let WO_Id = params.get("wfpflowid");
    let WO_TaskId = params.get("wfptaskid");
    let officecode = params.get("officecode");
    console.log("officecode", officecode);
    let options = params.get("options") ? params.get("options").split(",") : [];
    var order_id = 0;
    var customer_id = 0;
    var nombre_cliente = "";
    var pedido_modificado = "";
    var costo_envio = "";
    var descuento = "";
    var direccion_1 = "";
    var tipo_envio;
    var zona = "";
    var pedido_parcial = "";
    var mixed_skus = [];
    var flowcode;
    var formButtons = [];
    var metodo_pago = "";
    var monto_total = "";
    var sub_total = "";
    var sendstore = "";
    for (let o in options) {
      formButtons.push({ optioncode: "op" + o, optionname: options[o] });
    }

    let storeCodeI = "";
    if (officecode == "LP01") {
      storeCodeI = "MD-LP01";
    } else if (officecode == "SC04") {
      storeCodeI = "MD-SC04";
    } else if (officecode == "CB01") {
      storeCodeI = "MD-CB01";
    } else if (officecode == "SU01") {
      storeCodeI = "MD-SU01";
    } else if (officecode == "LP02") {
      storeCodeI = "MD-LP02";
    } else if (officecode == "SC02") {
      storeCodeI = "MD-SC02";
    }

    this.setState({
      isLoadedTable: false,
      WO_Id: WO_Id,
      WO_TaskId: WO_TaskId,
      loadingData: true,
      token: token,
      formButtons: formButtons,
      storeCodeI: storeCodeI,
    });


    await axios({
      headers: {
        crossdomain: true,
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      url: API_CONFIG.xflowurl + "/engine-users/decrypt-token",
      //url : "http://localhost:4042/api/v1"+"/engine-users/decrypt-token",
      method: "post",
    })
      .then((response) => response.data)
      .then((data) => {
        //console.log("user:", data.token.USERNAME);
        if (data.status) {
          this.setState({
            ...this.state,
            invoice_user: data.token.USERNAME,
          });
        }
      });


    await axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      url: API_CONFIG.xflowurl + "/engine-users/get-task-details",
      //url : "http://localhost:4042/api/v1"+"/engine-users/get-task-details",
      data: {
        wfptaskid: WO_TaskId,
      },
      method: "post",
    })
      .then((response) => response.data)
      .then((data) => {
        flowcode = data.task.FLOWCODE;
        console.log(flowcode);
        if (flowcode === "flowBolCI08") {
          this.setState({
            validaFactura: true,
          });
        }
        if (data.status) {
          for (let v in data.flowvars) {
            let flowVar = data.flowvars[v];
            if (flowVar.VARNAME === "order_id") {
              order_id = flowVar.VARVALUE;
            }
            if (flowVar.VARNAME === "customer_id") {
              customer_id = flowVar.VARVALUE;
            }
            if (flowVar.VARNAME === "nombre_cliente") {
              nombre_cliente = flowVar.VARVALUE;
            }
            if (flowVar.VARNAME === "pedido_modificado") {
              pedido_modificado = flowVar.VARVALUE;
            }
            if (flowVar.VARNAME === "shipping_cost") {
              costo_envio = flowVar.VARVALUE;
            }
            if (flowVar.VARNAME === "discount") {
              descuento = flowVar.VARVALUE;
            }
            if (flowVar.VARNAME === "direccion_1") {
              direccion_1 = flowVar.VARVALUE;
            }
            if (flowVar.VARNAME === "zona") {
              zona = flowVar.VARVALUE;
            }
            if (flowVar.VARNAME === "pedido_parcial") {
              pedido_parcial = flowVar.VARVALUE;
            }

            if (flowVar.VARNAME === "sendstore") {
              sendstore = flowVar.VARVALUE;
            }

            if (flowVar.VARNAME === "metodo_pago") {
              console.log("metodo_pago", flowVar.VARVALUE);

              let varPago = flowVar.VARVALUE;

              if (varPago === "Pago online") {
                varPago = "TODOTIX";
              }

              console.log("varPago", varPago);

              let resultado = this.state.metodos_orig.find(
                (metodo) => metodo.value === varPago
              );
              if (resultado) {
                metodo_pago = varPago;
              }
            }
            if (flowVar.VARNAME === "monto_total") {
              monto_total = flowVar.VARVALUE;
            }
            if (flowVar.VARNAME === "subtotal") {
              sub_total = flowVar.VARVALUE;
            }

            if (flowVar.VARNAME === "subtotal") {
              sub_total = flowVar.VARVALUE;
            }
            if (flowVar.VARNAME === "tipo_envio") {
              tipo_envio = flowVar.VARVALUE;
            }

            if (flowVar.VARNAME === "tipo_documento") {
              const tipos_documento = this.state.tipos_documento.map(
                (documento) => {
                  if (documento.text === flowVar.VARVALUE) {
                    return { ...documento, checked: true };
                  }
                  return documento;
                }
              );
              this.setState({ tipos_documento: tipos_documento });
            }

            if (flowVar.VARNAME === "mixed_skus") {
              try {
                mixed_skus = JSON.parse(flowVar.VARVALUE);
              } catch (ex) {
                console.log("EX", ex);
              }
            }
            this.setState({ [flowVar.VARNAME]: flowVar.VARVALUE });
          }
        }
      });

    if (tipo_envio !== "weigth") {
      console.log("tipo envio", tipo_envio);
      this.setState({
        validaFactura: true,
      });
    }

    var metodos = this.state.metodos_orig;
    for (var k in metodos) {
      let obj = metodos[k];
      if (obj["value"] == metodo_pago) {
        obj["checked"] = true;
      }
    }

    var fcosto_envio = costo_envio ? parseFloat(costo_envio, 2).toFixed(2) : 0;
    var fdescuento = descuento ? parseFloat(descuento, 2).toFixed(2) : 0;
    var fsub_total = sub_total ? parseFloat(sub_total, 2).toFixed(2) : 0;
    /*
    console.log("metodo_pago", metodo_pago);
    console.log("monto_total", monto_total);
    console.log("sub_total", sub_total);
    console.log("fcosto_envio", fcosto_envio);
*/

    if (flowcode === "flowBolCI02") {
      //console.log("YESSSSSSSSSSS")
      sendstore = "YES";
    }

    this.setState({
      ...this.state,
      metodos: metodos,
      metodo_pago: metodo_pago,
      sendstore: sendstore,
      monto_total: monto_total,
      descuento: fdescuento,
      costo_envio: fcosto_envio,
      sub_total: fsub_total,
    });
    if (metodo_pago === "TODOTIX") {
      this.setState({ monto_real: monto_total });
    }

    //let idTask = this.props.match.params.idTask;
    let url = API_CONFIG.casaideas + "/externalapi/execute/getorderById";

    await axios({
      crossdomain: true,
      url: url,
      method: "post",
      data: {
        username: casaideas_credentials.user,
        password: casaideas_credentials.password,
        order_id: order_id,
        customer_id: customer_id,
        adminpath: "matrix",
      },
    })
      .then((response) => response.data)
      .then((data) => {
        var arr = [];
        var arr2 = [];
        var i = 0;
        if (data.success) {
          data = {
            resp: "OK",
            rows: data.response[0].products,
          };
        }
        if (data.resp === "OK" && data.rows.length > 0) {
          if (pedido_parcial === "SI") {
            for (let k in mixed_skus) {
              let sku = mixed_skus[k];
              //console.log("sku original ", sku.sku)

              let isValid = false;
              for (let m in data.rows) {
                let key = data.rows[m];
                if (sku.sku === key.sku) {
                  if (flowcode === "flowBolCI07") {
                    //console.log("tienda online", sku.sku , key.sku , officecode , sku.store)
                    //pickup del restante de la otra tienda
                    if (officecode !== sku.store) {
                      arr.push({
                        id: key,
                        product_id: key.product_id,
                        productName: key.name,
                        productModel: key.model,
                        productPrice: key.price,
                        productQty: key.quantity,
                        productImage: key.image,
                        productSKU: key.sku,
                        state: "loaded",
                      });
                    } else {
                      arr2.push({
                        id: key,
                        product_id: key.product_id,
                        productName: key.name,
                        productModel: key.model,
                        productPrice: key.price,
                        productQty: key.quantity,
                        productImage: key.image,
                        productSKU: key.sku,
                        state: "loaded",
                      });
                    }
                  } else {
                    //console.log("tienda no online")
                    //es la tienda original
                    if (officecode === sku.store) {
                      arr.push({
                        id: key,
                        product_id: key.product_id,
                        productName: key.name,
                        productModel: key.model,
                        productPrice: key.price,
                        productQty: key.quantity,
                        productImage: key.image,
                        productSKU: key.sku,
                        state: "loaded",
                      });
                    } else {
                      arr2.push({
                        id: key,
                        product_id: key.product_id,
                        productName: key.name,
                        productModel: key.model,
                        productPrice: key.price,
                        productQty: key.quantity,
                        productImage: key.image,
                        productSKU: key.sku,
                        state: "loaded",
                      });
                    }
                  }
                }
              }
            }
          } else {
            for (let key in data.rows) {
              arr.push({
                id: key,
                product_id: data.rows[key].product_id,
                productName: data.rows[key].name,
                productModel: data.rows[key].model,
                productPrice: data.rows[key].price,
                productQty: data.rows[key].quantity,
                productImage: data.rows[key].image,
                productSKU: data.rows[key].sku,
                state: "loaded",
              });
            }
          }
        }

        this.setState({
          ...this.state,
          orders: arr,
          orders2: arr2,
          isLoadedTable: true,
          customerName: nombre_cliente,
          customerAdd: direccion_1,
          customerZone: zona,
          customer_id: customer_id,
          loadingData: false,
          existingPreviousOrder: false /*revisar*/,
          isDeleted: false /*revisar*/,
          order_id: order_id /*revisar*/,
        });
      });
  };

  toggleModal = (nr) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };

  executeTask = async (option, optionName) => {
    var responseValidation = true;

    var f = new Date(this.state.from_date);
    var fecha =
      (f.getDate() < 10 ? "0" + f.getDate() : f.getDate()) +
      "/" +
      (f.getMonth() + 1 < 10 ? "0" + (f.getMonth() + 1) : f.getMonth() + 1) +
      "/" +
      (f.getFullYear() + "");

    console.log(fecha, option, optionName, this.state.formButtons.length);
    //var fecha = res [2]+"/"+res[1]+"/"+res[0]
    this.setState({ isExecuting: true });

    var monto_real = this.state.monto_real;
    var monto_2 = this.state.monto_2;
    var metodo_pago = this.state.metodo_pago;
    var metodo_pago_2 = this.state.metodo_pago_2;
    var motivo_no_entrega = this.state.motivo_no_entrega;
    //console.log(this.state.formButtons.buttonsList)

    if (optionName == "No entregado" && this.state.formButtons.length > 1) {
      if (motivo_no_entrega == "") {
        var msj_validacion = "Seleccione el motivo de no Entrega";
        toast.warning(msj_validacion, {
          closeButton: true,
        });
        responseValidation = false;
      }
    } else {
      console.log(
        "this.state.validaFactura",
        this.state.validaFactura,
        this.state.numero_factura,
        this.state.metodo_pago
      );
      motivo_no_entrega = "";

      if (this.state.numero_factura === "" && this.state.validaFactura) {
        responseValidation = false;
        toast.warning("Debe completar la factura", { closeButton: true });
      }

      if (this.state.razon_social === "" && this.state.validaFactura) {
        responseValidation = false;
        toast.warning("Debe completar la razon social", { closeButton: true });
      }

      if (metodo_pago == "") {
        var msj_validacion = "Seleccion el campo metodo de pago";
        toast.warning(msj_validacion, {
          closeButton: true,
        });
        responseValidation = false;
      }

      if (monto_real == "") {
        var msj_validacion = "Complete el campo monto pagado";
        toast.warning(msj_validacion, {
          closeButton: true,
        });
        responseValidation = false;
      } else {
        if (isNaN(monto_real)) {
          var msj_validacion = "El campo monto pagado debe ser numero";
          toast.warning(msj_validacion, {
            closeButton: true,
          });
          responseValidation = false;
        }
      }
      if (metodo_pago_2 != "") {
        if (monto_2 == "") {
          var msj_validacion = "Complete el campo monto pagado 2";
          toast.warning(msj_validacion, {
            closeButton: true,
          });
          responseValidation = false;
        } else {
          if (isNaN(monto_2)) {
            var msj_validacion = "El campo monto pagado 2 debe ser numero";
            toast.warning(msj_validacion, {
              closeButton: true,
            });
            responseValidation = false;
          }
        }
      }
    }

    if (!responseValidation) {
      this.setState({
        ...this.state,
        responseTask: "NOK",
        isExecuting: false,
        modal6: false,
      });
    } else {
      this.setState({ responseTask: null });

      var params = {
        comment_entrega: this.state.observation,
        //fecha_pago: fecha,
        monto_real: this.state.monto_real,
        monto_2: this.state.monto_2,
        metodo_pago: this.state.metodo_pago,
        metodo_pago_2: this.state.metodo_pago_2,
        motivo_no_entrega: motivo_no_entrega,

        TRX: this.state.TRX,
        numero_factura: this.state.numero_factura,
      };

      //solo cuando es diferente de evento
      if (this.state.tipo_envio !== "event") {
        params["fecha_pago"] = fecha;
        console.log("fecha pago", fecha);
      } else {
        console.log("NO enviamos fecha pago");
      }
      await axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          origin: "x-requested-with",
          "Access-Control-Allow-Headers":
            "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.token,
        },
        url: API_CONFIG.xflowurl + "/engine-users/execute-task",
        //url : "http://localhost:4042/api/v1"+"/engine-users/execute-task",
        data: {
          parentid: this.state.WO_TaskId,
          id: this.state.WO_TaskId,
          isParent: this.state.isParent,
          optionValue: optionName,
          params: params,
        },
        method: "put",
      })
        .then((response) => response.data)
        .then((data) => {
          if (data.status) {
            toast.success("Procesado con exito", {
              closeButton: true,
            });

            if (this.state.reload) {
              console.log("reload parent");
              try {
                //window.opener.location.reload();
              } catch (ex) {
                console.log("error closing");
              }
              window.close();
            }
          } else {
            toast.error("Error ejecutando tareas, mensaje:" + data.message, {
              closeButton: true,
            });
          }
          this.setState({
            ...this.state,
            isExecuting: false,
            modal6: false,
          });
        });
    }
  };

  render() {
    return (
      <>
        {this.state.loadingData ? (
          <Loader idLoading={this.state.loadingData} />
        ) : (
          <>
            <Loader idLoading={this.state.isExecuting} />

            <MDBCard>
              <h3
                style={{
                  zIndex: "1000",
                  fontWeight: "600",
                  color: "#000",
                }}
              >
                {" "}
                {"Pedido #" + this.state.order_id}
              </h3>

              <MDBRow>
                <MDBCol size="12" sm="6" md="3" lg="2">
                  SubTotal
                </MDBCol>
                <MDBCol
                  size="12"
                  sm="6"
                  md="3"
                  lg="2"
                  xl="2"
                  className="text-right"
                >
                  <h6
                    style={{
                      zIndex: "1000",
                      fontWeight: "400",
                      color: "#000",
                    }}
                  >
                    {" "}
                    {this.state.sub_total + " Bs."}
                  </h6>{" "}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="12" sm="6" md="3" lg="2">
                  Descuento
                </MDBCol>
                <MDBCol
                  size="12"
                  sm="6"
                  md="3"
                  lg="2"
                  xl="2"
                  className="text-right"
                >
                  <h6
                    style={{
                      zIndex: "1000",
                      fontWeight: "400",
                      color: "#000",
                    }}
                  >
                    {" "}
                    {this.state.descuento + " Bs."}
                  </h6>{" "}
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol size="12" sm="6" md="3" lg="2">
                  Costo de Envio
                </MDBCol>
                <MDBCol
                  size="12"
                  sm="6"
                  md="3"
                  lg="2"
                  xl="2"
                  className="text-right"
                >
                  <h6
                    style={{
                      zIndex: "1000",
                      fontWeight: "400",
                      color: "#000",
                    }}
                  >
                    {" "}
                    {this.state.costo_envio + " Bs."}
                  </h6>{" "}
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol size="12" sm="6" md="3" lg="2">
                  {" "}
                  <h5
                    style={{
                      zIndex: "1000",
                      fontWeight: "500",
                      color: "#000",
                    }}
                  >
                    {" "}
                    Total:
                  </h5>
                </MDBCol>
                <MDBCol
                  size="12"
                  sm="6"
                  md="3"
                  lg="2"
                  xl="2"
                  className="text-right"
                >
                  {" "}
                  <h5
                    style={{
                      zIndex: "1000",
                      fontWeight: "500",
                      color: "#000",
                    }}
                  >
                    {" "}
                    {this.state.monto_total + " Bs."}
                  </h5>
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol size="12" className="text-left">
                  <h4
                    style={{
                      zIndex: "1000",
                      fontWeight: "400",
                      color: "#000",
                    }}
                  >
                    {" "}
                    Detalle de la orden{" "}
                  </h4>
                </MDBCol>
              </MDBRow>

              {this.state.orders.map((event) => (
                <p
                  className="ml-1"
                  key={"event1" + event.id}
                  style={{ width: "98%" }}
                >
                  <Event
                    id={event.id}
                    product_id={".000" + event.product_id}
                    productName={event.productName}
                    productQty={event.productQty}
                    productPrice={event.productPrice}
                    productModel={event.productModel}
                    productImage={event.productImage}
                    productSKU={event.productSKU}
                    state={event.state}
                    onDelete={null}
                    onEdit={null}
                    onPick={this.handlePick}
                  />
                </p>
              ))}

              <MDBRow className="ml-1 mt-2" style={{ width: "99%" }}>
                {this.state.tipo_envio !== "event" && (
                  <MDBCol sm="12" md="6" lg="6" className="text-left">
                    <span style={{ color: "#909090" }}> Fecha de pago </span>{" "}
                    <MDBDatePicker
                      style={{ marginTop: "-1.5rem" }}
                      key="from"
                      name="from"
                      id="from"
                      format="DD/MM/YYYY"
                      value={this.state.from_date}
                      cancelLabel="Cancelar"
                      getValue={this.getPickerValueFrom}
                    />
                  </MDBCol>
                )}

                {this.state.tipo_envio !== "event" && (
                  <MDBCol sm="12" md="6" lg="6" className=" text-left left">
                    <MDBSelect
                      className="is-invalid"
                      key="store"
                      id="store"
                      color="primary"
                      getValue={this.selectHandler3}
                      options={this.state.motivos}
                      label="Motivo no Entrega"
                    />
                  </MDBCol>
                )}
              </MDBRow>

              <MDBRow className="ml-1" style={{ width: "99%" }}>
                <MDBCol sm="12" md="6" lg="6" className=" text-left left">
                  {this.state.tipo_envio === "event" ? (
                    <MDBInput
                      className="mt-3"
                      id={"metodo_pago"}
                      key={"metodo_pago"}
                      name={"metodo_pago"}
                      disabled={true}
                      readonly={true}
                      value={this.state.metodo_pago}
                      size="sm"
                      label="Método de Pago"
                    />
                  ) : (
                    <MDBSelect
                      className="is-invalid"
                      key="metodo_pago"
                      id="metodo_pago"
                      color="primary"
                      getValue={this.selectHandler1}
                      options={this.state.metodos}
                      label="Método de Pago"
                    />
                  )}
                </MDBCol>
                <MDBCol sm="12" md="6" lg="6" className=" text-left left">
                  <MDBInput
                    name="monto_real"
                    onChange={this.changeHandler}
                    type="text"
                    id="monto_real"
                    key="monto_real"
                    value={this.state.monto_real}
                    label="Monto Pagado"
                    required
                  ></MDBInput>
                </MDBCol>
              </MDBRow>

              <MDBRow className="ml-1" style={{ width: "99%" }}>
                {this.state.tipo_envio !== "event" && (
                  <MDBCol sm="12" md="6" lg="6" className=" text-left left">
                    <MDBSelect
                      className="is-invalid"
                      key="metodo_pago_2"
                      id="metodo_pago_2"
                      color="primary"
                      getValue={this.selectHandler2}
                      options={this.state.metodos2}
                      label="Metodo de Pago 2"
                    />
                  </MDBCol>
                )}

                {this.state.tipo_envio !== "event" && (
                  <MDBCol sm="12" md="6" lg="6" className=" text-left left">
                    <MDBInput
                      name="monto_2"
                      onChange={this.changeHandler}
                      type="text"
                      id="monto_2"
                      key="monto_2"
                      disabled={this.state.metodo_pago_2 == "" ? true : false}
                      value={this.state.monto_2}
                      label="Monto pagado 2"
                      required
                    ></MDBInput>
                  </MDBCol>
                )}
              </MDBRow>

              {this.state.sendstore !== "YES" && (
                <MDBRow className="ml-1" style={{ width: "99%" }}>
                  <MDBCol size="12" sm="12" md="6" lg="6">
                    <div
                      style={{
                        border: "1px solid #00c851",
                        padding: 5,
                        borderRadius: 5,
                      }}
                    >
                      <h5>Facturación en linea</h5>

                      {this.state.invoice_status ===
                        "EJECUTADO CORRECTAMENTE" ||
                      this.state.invoice_status === "BYPASS" ? (
                        <MDBAlert
                          color="info"
                          className="d-flex align-items-center"
                        >
                          <MDBIcon icon="info-circle" className="mr-2" />
                          Factura ya generada
                        </MDBAlert>
                      ) : (
                        <MDBBtn
                          size="sm"
                          color="success"
                          onClick={() => {
                            console.log("thiss", this.state);
                            if (this.state.metodo_pago === "") {
                              toast.error(
                                "Por favor escoja un Métdoo de pago",
                                {
                                  closeButton: true,
                                }
                              );
                            } else {
                              this.setState({
                                modal33: true,
                              });
                            }
                          }}
                        >
                          <MDBIcon icon="file-invoice" /> Generar Factura
                        </MDBBtn>
                      )}

                      <div style={{ borderTop: "1px solid #00c851" }}>
                        <MDBRow>
                          <MDBCol
                            sm="12"
                            md="6"
                            lg="3"
                            className=" text-left left"
                          >
                            Número Factura:
                          </MDBCol>
                          <MDBCol
                            sm="12"
                            md="6"
                            lg="9"
                            className=" text-left left"
                          >
                            {this.state.numero_factura == ""
                              ? "0"
                              : this.state.numero_factura}
                            <input
                              type="hidden"
                              value={this.state.numero_factura}
                            />
                          </MDBCol>
                        </MDBRow>

                        <MDBRow>
                          <MDBCol
                            sm="12"
                            md="6"
                            lg="3"
                            className=" text-left left"
                          >
                            Respuesta:
                          </MDBCol>
                          <MDBCol
                            sm="12"
                            md="6"
                            lg="9"
                            className=" text-left left"
                          >
                            {this.state.invoice_status}
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol
                            sm="12"
                            md="6"
                            lg="3"
                            className=" text-left left"
                          >
                            Descripción:
                          </MDBCol>
                          <MDBCol
                            sm="12"
                            md="6"
                            lg="9"
                            className=" text-left left"
                          >
                            {this.state.invoice_status != "PENDIENTE" ? (
                              <>
                                <MDBAlert
                                  color={
                                    this.state.invoice_status ===
                                    "EJECUTADO CON ERROR"
                                      ? "danger"
                                      : "success"
                                  }
                                  className="d-flex align-items-center"
                                >
                                  <MDBIcon
                                    icon={
                                      this.state.invoice_status ===
                                      "EJECUTADO CON ERROR"
                                        ? "times-circle"
                                        : "check-circle"
                                    }
                                    className="mr-2"
                                  />
                                  {this.state.invoice_response}
                                </MDBAlert>
                              </>
                            ) : (
                              <>{this.state.invoice_response}</>
                            )}
                          </MDBCol>
                        </MDBRow>
                      </div>
                    </div>
                  </MDBCol>

                  <MDBCol
                    size="12"
                    sm="6"
                    md="6"
                    lg="6"
                    xl="6"
                    className="text-right"
                  >
                    <MDBInput
                      style={{ margin: "0" }}
                      id={"TRX"}
                      key={"TRX"}
                      name={"TRX"}
                      disabled={this.state.invoice_status !== "BYPASS"}
                      readonly={this.state.invoice_status !== "BYPASS"}
                      value={this.state.TRX}
                      onChange={this.changeHandler}
                      size="sm"
                      label="TRX"
                    />

                    {this.state.invoice_status === "BYPASS" && (
                      <MDBInput
                        className="mt-3"
                        id={"numero_factura"}
                        key={"numero_factura"}
                        name={"numero_factura"}
                        disabled={this.state.invoice_status !== "BYPASS"}
                        readonly={this.state.invoice_status !== "BYPASS"}
                        value={this.state.numero_factura}
                        onChange={this.changeHandler}
                        size="sm"
                        label="Número Factura"
                      />
                    )}
                  </MDBCol>
                </MDBRow>
              )}

              <MDBRow style={{ marginTop: "-1.5rem" }}>
                <MDBCol sm="12" md="12" lg="12" className="text-left left">
                  <MDBInput
                    type="textarea"
                    id="observation"
                    label="Comentario Adicional"
                    rows="2"
                    name="observation"
                    key="observation"
                    value={this.state.observation}
                    onChange={this.changeHandler}
                    icon="pencil-alt"
                  />

                  {this.state.datos_enviados}
                </MDBCol>
              </MDBRow>
              <MDBRow
                className="text-right"
                style={{ marginTop: 15, marginBottom: 15 }}
              >
                <MDBCol md="12">
                  {this.state.formButtons.map((item) => (
                    <MDBBtn
                      color="primary"
                      size="sm"
                      onClick={() => {
                        this.setState({
                          modal6: true,
                          optionMessage:
                            "Confirma la opcion " + item.optionname + "?",
                          optionCode: item.optioncode,
                          optionName: item.optionname,
                        });
                      }}
                      key={item.optioncode}
                    >
                      {" "}
                      {item.optionname}
                    </MDBBtn>
                  ))}
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </>
        )}

        <MDBModal
          cascading
          centered
          isOpen={this.state.modal33}
          toggle={this.toggleModal(33)}
          size="lg"
        >
          <MDBModalBody>
            <span className="h2-responsive mb-4">
              {" "}
              <MDBIcon icon="cart-arrow-down" className="mr-1" />{" "}
              {"Facturación en linea"}
            </span>

            <>
              {this.state.metodo_pago === "TARJETA ATC" && (
                <div className="row">
                  <div className=" col-sm-12 col-md-9">
                    <h5>Complete los datos de la tarjeta</h5>
                  </div>
                  <div className=" col-sm-12 col-md-9">
                    <div className="row">
                      <div className="col-3 col-sm-3 col-md-2">
                        <MDBInput
                          id="tarjeta_1"
                          key="tarjeta_1"
                          name="tarjeta_1"
                          placeHolder="0000"
                          maxLength="4"
                          value={this.state.tarjeta_1}
                          onChange={this.changeHandler}
                          size="xs"
                        />
                      </div>
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "600",
                          display: "flex",
                          alignItems: "center",
                        }}
                        className="col-3 col-sm-3 col-md-2"
                      >
                        {" "}
                        XXXX
                      </div>
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "600",
                          display: "flex",
                          alignItems: "center",
                        }}
                        className="col-3 col-sm-3 col-md-2"
                      >
                        {" "}
                        XXXX{" "}
                      </div>
                      <div className="col-3 col-sm-3 col-md-2">
                        <MDBInput
                          id="tarjeta_2"
                          key="tarjeta_2"
                          name="tarjeta_2"
                          placeHolder="0000"
                          maxLength="4"
                          value={this.state.tarjeta_2}
                          onChange={this.changeHandler}
                          size="xs"
                        />
                      </div>
                      <div className="col-12 col-sm-12 col-md-4"></div>
                    </div>
                  </div>
                </div>
              )}
              <MDBRow className="text-right mt-3">
                <MDBCol md="9">
                  <MDBInput
                    id="razon_social"
                    key="razon_social"
                    name="razon_social"
                    value={this.state.razon_social}
                    onChange={this.changeHandler}
                    size="sm"
                    label="Razón social"
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow className="text-right mt-3">
                <MDBCol md="9">
                  <MDBInput
                    id="nit"
                    key="nit"
                    name="nit"
                    value={this.state.nit}
                    onChange={this.changeHandler}
                    size="sm"
                    label="NIT/CI"
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow className="text-right mt-3">
                <MDBCol md="9">
                  <MDBSelect
                    className="is-invalid"
                    key="tipo_documento"
                    id="tipo_documento"
                    color="primary"
                    getValue={this.selectHandler4}
                    options={this.state.tipos_documento}
                    label={"Tipo de Documento"}
                  />
                </MDBCol>
                <MDBCol md="3">
                  <MDBBtn
                    color="success"
                    onClick={() => {
                      this.generateInvoice();
                    }}
                    className="w-100"
                    size="sm"
                  >
                    Generar
                  </MDBBtn>

                  <MDBBtn
                    color="primary"
                    onClick={() => {
                      this.generateInvoice(true);
                    }}
                    className="w-100"
                    size="sm"
                  >
                    Bypass
                  </MDBBtn>

                  <MDBBtn
                    size="sm"
                    color="danger"
                    className="w-100"
                    onClick={() => {
                      this.setState({
                        modal33: false,
                      });
                    }}
                  >
                    Cancelar
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </>
          </MDBModalBody>
        </MDBModal>

        <MDBModal
          cascading
          centered
          isOpen={this.state.modal6}
          toggle={this.toggleModal(6)}
          size="md"
        >
          <MDBModalBody className="text-center">
            <span className="h4-responsive text-center text-uppercase">
              {" "}
              {this.state.optionMessage}
            </span>
            <hr />
            <MDBRow className="text-center">
              <MDBCol md="12">
                <MDBBtnGroup size="sm">
                  <MDBBtn
                    outline
                    onClick={() => {
                      this.setState({ modal6: false });
                    }}
                    color="warning"
                  >
                    {" "}
                    <span className="h4-responsive">Cancelar</span>
                  </MDBBtn>
                  <MDBBtn
                    onClick={() => {
                      this.setState({ modal6: false });
                      this.executeTask(
                        this.state.optionCode,
                        this.state.optionName
                      );
                    }}
                    color="primary"
                  >
                    {" "}
                    <span className="h5-responsive">Aceptar</span>{" "}
                  </MDBBtn>
                </MDBBtnGroup>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>

        <ToastContainer
          hideProgressBar={true}
          newestOnTop={false}
          position={"bottom-right"}
          autoClose={3000}
        />
      </>
    );
  }
}

class Event extends React.Component {
  render() {
    return this.props.state === "deleting" ? (
      <MDBCard
        style={{
          opacity: "0.4",
          backgroundColor: "#999",
        }}
        className="align-items-center justify-content-center"
      >
        <p></p>
        <div className="media mt-4 align-middle">
          <div className="media-body mb-3 mb-lg-3">
            <MDBAnimation type="rotateOut" infinite>
              <MDBIcon
                style={{
                  zIndex: "1050",
                }}
                size="2x"
                far
                icon="trash-alt"
              />
            </MDBAnimation>
          </div>
        </div>
        <p></p>
      </MDBCard>
    ) : (
      <MDBCard className="">
        <div className="media mt-1">
          <span className="h4-responsive font-weight-bold mr-3 align-items-right justify-content-right">
            {this.props.productQty}
            <br />

            <img
              src={this.props.productImage}
              alt="thumbnail"
              height="60px"
              width="60px"
              className="img-thumbnail"
            />
          </span>
          <div className="media-body mb-3 mb-lg-3">
            <span className="h6 mt-0 font-weight-bold">
              {this.props.productName}{" "}
            </span>{" "}
            <hr className="hr-bold my-2" />
            {this.props.product_id && (
              <React.Fragment>
                <p className="font-smaller mb-0">
                  <MDBRow className="align-items-left justify-content-left">
                    <MDBCol md="12">
                      <MDBIcon icon="shopping-basket" /> Codigo:{" "}
                      {this.props.productModel}
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="align-items-left justify-content-left">
                    <MDBCol md="12">
                      <MDBIcon icon="barcode" /> SKU: {this.props.productSKU}
                    </MDBCol>
                  </MDBRow>
                </p>
              </React.Fragment>
            )}
          </div>
        </div>
        {this.props.productQty && (
          <p className="p-2 mb-4  blue-grey lighten-5 blue-grey lighten-5">
            {"Cantidad: " +
              this.props.productQty +
              ", Precio unitario: " +
              this.props.productPrice +
              ", Total " +
              parseInt(this.props.productPrice.replace("Bs.", "")) *
                parseInt(this.props.productQty) +
              "Bs."}
          </p>
        )}
      </MDBCard>
    );
  }
}

export default externalForm02;
