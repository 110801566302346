/*****components***/
import React, { Component } from "react";
import { Switch } from "antd";
import {
  toast,
  ToastContainer,
  MDBModal,
  MDBIcon,
  MDBBtn,
  MDBInput,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBContainer,
  MDBRow,
  MDBCol,
} from "mdbreact";
import { OP_SECRET_KEY } from "../../Config/config-env.js";
import { API_CONFIG } from "../../Config/config-env.js";
import axios from "axios";

/*custom components*/
import PageLoading from "../../Components/PageLoading/index.js";
import Table from "../../Components/Table/index.js";
import Filters from "../../Components/Filters/index.js";
import Modal from "../../Components/Modal/index.js";
import PageHeader from "../../Components/PageHeader/index.js";

/*functions*/
import {
  searchHandler,
  callexternalApi,
  callApi,
  find,
} from "../../Config/functions.js";

import ReactExport from "react-export-excel-xlsx-fix";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var f = new Date();
var fromDate = new Date();
fromDate.setDate(f.getDate() - (process.env.REACT_APP_API_CONFIG ? 7 : 30));

var mes =
  f.getUTCMonth() + 1 < 10 ? "0" + (f.getUTCMonth() + 1) : f.getUTCMonth() + 1;
var dia = f.getUTCDate() < 10 ? "0" + f.getUTCDate() : f.getUTCDate();
var date_label = f.getUTCFullYear() + "." + mes + "." + dia;

class page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //show : null,
      modal1: false,
      isLoading: true,
      api_url: "",
      date_label: date_label,
      modalClass: "modal-full-height modal-lg",
      modalIamgeClass: "modal-lg",
      excelrep1: "",
      excelrep2: "",
      updateStatus: "OK",
      stores: [
        {
          store: "Sucursal La Paz Megacenter",
          code: "LP01",
        },
        {
          store: "Sucursal La Paz Multicine",
          code: "LP02",
        },
        {
          store: "Sucursal Cochabamba",
          code: "CB01",
        },
        {
          store: "Sucursal Santa Cruz Banzer",
          code: "SC04",
        },
        {
          store: "Sucursal Santa Cruz Ventura",
          code: "SC02",
        },
        {
          store: "Sucursal Sucre",
          code: "SU01",
        },
      ],
      isChecked: true,
      /*table filter*/
      filterConfig: {
        ORDERID: {
          label: "Nro Pedido Desde",
          type: "NUMBER",
          value:
            process.env.REACT_APP_API_CONFIG === "http://localhost:2011/api/v1"
              ? "30951"
              : "",
        },
        ORDERID_TO: {
          label: "Nro Pedido Hasta",
          type: "NUMBER",
          value:
            process.env.REACT_APP_API_CONFIG === "http://localhost:2011/api/v1"
              ? "30951"
              : "",
        },
        CUSTOMER_NAME: { label: "Nombre Cliente", type: "TEXT", value: "" },
        FROMDATE: { label: "Desde", type: "DATE", value: fromDate },
        TODATE: { label: "Hasta", type: "DATE", value: new Date() },
        CITY: {
          label: "Ciudad",
          type: "SELECT",
          values: [{ text: "TODAS", value: "" }],
          value: "",
        },
        STOREID: {
          label: "Tienda",
          type: "MULTIPLE",
          values: [{ text: "TODAS", value: "" }],
          value: "",
        },
        STATUSID: {
          label: "Estado",
          type: "MULTIPLE",
          values: [{ text: "TODOS", value: "" }],
          value: "",
        },
        PAYMENTTYPEID: {
          label: "Tipo Pago",
          type: "MULTIPLE",
          values: [{ text: "TODOS", value: "" }],
          value: "",
        },
        SHIPPINGTYPEID: {
          label: "Typo Env\u00EDo",
          type: "MULTIPLE",
          values: [{ text: "TODOS", value: "" }],
          value: "",
        },

        MIN_PRICE: { label: "Monto Max", type: "NUMBER", value: "0" },
        MAX_PRICE: { label: "Monto Min", type: "NUMBER", value: "999999" },
        HAVE_COUPON: {
          label: "Tiene Convenio",
          type: "SELECT",
          value: "",
          values: [
            { text: "TODOS", value: "" },
            { text: "SI", value: "YES" },
            { text: "NO", value: "NO" },
          ],
        },
        COUPONNAME: {
          label: "Nombre del Cup\u00F3n",
          type: "SELECT",
          value: "",
          values: [{ text: "TODOS", value: "" }],
        },
      },
      showMore: false,
      /*table data*/
      tableData: {
        columns: [],
        rows: [],
      },

      tableData2: {
        columns: [],
        rows: [],
      },

      defCols: [
        { value: "ORDERID", enabled: true, title: "Pedido", width: 150 },
        { value: "SKU_REALSTORE", enabled: true, title: "Tienda PickUp" },
        { value: "STORENAME", enabled: true, title: "Tienda", width: 150 },
        {
          value: "SKU_PRODUCTID",
          enabled: true,
          title: "Id Producto",
          width: 150,
        },
        {
          value: "SKU_PRODUCTNAME",
          enabled: true,
          title: "Nombre Genérico",
          width: 200,
        },
        {
          value: "SKU_CATEGORYNAME",
          enabled: true,
          title: "Categoría Madre",
          width: 200,
        },
        {
          value: "SKU_KEYVARIANT",
          enabled: true,
          title: "Key Variante",
          width: 150,
        },
        { value: "SKU", enabled: true, title: "SKU", width: 100 },
        {
          value: "SKU_NAME",
          enabled: true,
          title: "Nombre Específico",
          width: 200,
        },
        { value: "SKU_COLOR", enabled: true, title: "Color", width: 150 },
        { value: "SKU_SIZE", enabled: true, title: "Talla", width: 100 },
        {
          value: "SKU_REGULAR_PRICE",
          enabled: true,
          title: "Precio Regular",
          width: 150,
        },
        { value: "SKU_PRICE", enabled: true, title: "Precio", width: 150 },
        { value: "SKU_TOTAL", enabled: true, title: "Total SKU", width: 150 },
        { value: "SKU_QUANTITY", enabled: true, title: "Cantidad", width: 100 },
        {
          value: "SUBTOTAL_ORDER",
          enabled: true,
          title: "Subtotal",
          width: 150,
        },
        {
          value: "SHIPPINGCOST",
          enabled: true,
          title: "Costo de Envío",
          width: 150,
        },
        { value: "DISCOUNT", enabled: true, title: "Descuento", width: 150 },
        { value: "TOTAL_ORDER", enabled: true, title: "Total", width: 150 },
        { value: "STATUSNAME", enabled: true, title: "Estado", width: 150 },
        { value: "FIRSTNAME", enabled: true, title: "Nombre(s)", width: 150 },
        { value: "LASTNAME", enabled: true, title: "Apellido(s)", width: 150 },
        { value: "EMAIL", enabled: true, title: "Email", width: 200 },
        { value: "PHONE", enabled: true, title: "Teléfono", width: 150 },
        { value: "CUSTOMERID", enabled: true, title: "Id Cliente", width: 150 },
        {
          value: "CUSTOMERGROUP_ID",
          enabled: true,
          title: "Id Grupo Cliente",
          width: 150,
        },
        {
          value: "CUSTOMERGROUP",
          enabled: true,
          title: "Grupo Cliente",
          width: 150,
        },
        {
          value: "BIRTHDAY",
          enabled: true,
          title: "Fecha de Nacimiento",
          width: 150,
        },
        {
          value: "EVENTNAME",
          enabled: true,
          title: "Nombre Evento",
          width: 200,
        },
        { value: "EVENTTYPE", enabled: true, title: "Tipo Evento", width: 150 },
        { value: "PAYMENT_CITY", enabled: true, title: "Ciudad", width: 150 },
        {
          value: "PAYMENTMETHOD",
          enabled: true,
          title: "Método de Pago",
          width: 150,
        },
        {
          value: "SHIPPINGADDRESS",
          enabled: true,
          title: "Dirección de Envío",
          width: 200,
        },
        {
          value: "ADDITIONALSHIPPINGINSTRUCTIONS",
          enabled: true,
          title: "Instrucciones de Envío",
          width: 250,
        },
        {
          value: "ZONECODE",
          enabled: true,
          title: "Código de Zona",
          width: 150,
        },
        {
          value: "PICKUPDATE",
          enabled: true,
          title: "Fecha de Recojo",
          width: 150,
        },
        {
          value: "SHIPPING_CITY",
          enabled: true,
          title: "Ciudad de Envío",
          width: 150,
        },
        {
          value: "SHIPPINGMETHOD",
          enabled: true,
          title: "Método de Envío",
          width: 150,
        },
        {
          value: "SHIPPINGTYPE",
          enabled: true,
          title: "Tipo de Envío",
          width: 150,
        },
        {
          value: "HAVECOUPON",
          enabled: true,
          title: "Nombre Cupón",
          width: 150,
        },
        {
          value: "COUPONCODE",
          enabled: true,
          title: "Código Cupón",
          width: 150,
        },
        {
          value: "COUPONADMINCODE",
          enabled: true,
          title: "Código Interno",
          width: 150,
        },
        {
          value: "COUPONVALUE",
          enabled: true,
          title: "Valor Cupón",
          width: 150,
        },
        {
          value: "DATE_ADDED",
          enabled: true,
          title: "Fecha de Pedido",
          width: 150,
        },
        {
          value: "DATE_MODIFIED",
          enabled: true,
          title: "Fecha de Actualización",
          width: 150,
        },
        {
          value: "NOTA_SAP",
          enabled: true,
          title: "Nota sap",
          width: 150,
        },
      ],
    };
  }
  /*loading data*/
  componentDidMount = async () => {
    require("antd/dist/antd.css");

    let params = new URLSearchParams(this.props.location.search);
    let token = params.get("token");

    await axios({
      url: API_CONFIG.xflowurl + "/components/get-single-by-code",
      data: { component: "dashboards", code: "REP001" },
      headers: {
        "Access-Control-Allow-Origin": "*",
        origin: "x-requested-with",
        "Access-Control-Allow-Headers":
          "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      method: "post",
    })
      .then((response) => response.data)
      .then((data) => {
        let compinfo = data.data.DATA;
        // console.log(compinfo);
        this.setState({ compinfo: compinfo });
      });

    try {
      // console.log(token);
      let resp = await callApi(
        "/engine-users/decryptToken",
        "post",
        { token: token },
        token
      );

      //var api_url = "http://localhost:8080"
      var api_url = API_CONFIG.casaideas;

      let resp1 = await callexternalApi(
        api_url,
        "/api/frontend/getPaymentTypes",
        "post",
        { languageid: 3 }
      );
      let resp2 = await callexternalApi(
        api_url,
        "/api/frontend/getShippingTypes",
        "post",
        { languageid: 3 }
      );
      let resp3 = await callexternalApi(
        api_url,
        "/api/frontend/getStores",
        "post"
      );
      let resp4 = await callexternalApi(
        api_url,
        "/api/frontend/getStatusOrders",
        "post",
        { languageid: 3 }
      );
      let resp5 = await callexternalApi(
        api_url,
        "/api/frontend/getPolygons",
        "post"
      );

      let resp6 = await callexternalApi(
        api_url,
        "/externalapi/execute/getCoupons/key/" + OP_SECRET_KEY,
        "post",
        { username: "admin" }
      );

      const filterConfig = this.state.filterConfig;
      resp1.rows.map(function (item) {
        filterConfig.PAYMENTTYPEID.values.push({
          text: item.PAYMENTNAME,
          value: item.PAYMENTTYPEID,
        });
      });
      resp2.rows.map(function (item) {
        filterConfig.SHIPPINGTYPEID.values.push({
          text: item.SHIPPINGNAME,
          value: item.SHIPPINGTYPEID,
        });
      });
      resp3.rows.map(function (item) {
        filterConfig.STOREID.values.push({
          text: item.STRING_VALUE,
          value: item.DETAILDOMAINID,
        });
      });
      resp4.rows.map(function (item) {
        filterConfig.STATUSID.values.push({
          text: item.STATUSNAME,
          value: item.STATUSID,
        });
      });

      var cities = [];
      resp5.rows.map(function (item) {
        if (cities.indexOf(item.CITY) < 0) {
          cities.push(item.CITY);
        }
      });
      cities.map(function (item) {
        filterConfig.CITY.values.push({ text: item, value: item });
      });

      resp6.response.map(function (item) {
        filterConfig.COUPONNAME.values.push({
          text: item.COUPONNAME,
          value: item.COUPONNAME,
        });
      });

      this.setState({ api_url: api_url });
    } catch (err) {
      console.log(">>>>>>>>>>" + err);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  toggleModal = (nr) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber],
    });
  };

  ///Search Manager
  searchHandler = async (event) => {
    this.setState({ isLoading: true });

    let params = new URLSearchParams(this.props.location.search);
    let token = params.get("token");

    let components = this.state.compinfo.data;

    for (let i in components) {
      let component = components[i];
      let header = [];
      for (let j in component.values.options) {
        let obj = component.values.options[j];
        header.push(obj);
      }
      component["header"] = header;
    }

    let filterDefinition = [
      {
        name: "INSERT_DATE",
        type: "daterange",
        value: [
          this.state.filterConfig.FROMDATE.value,
          this.state.filterConfig.TODATE.value,
        ],
      },
      {
        name: "AGENCIA",
        type: "select",
        value: "",
      },
      {
        name: "METODO_PAGO",
        type: "select",
        value: "",
      },
      {
        name: "TIPODEENVIO",
        type: "select",
        value: "",
      },
      {
        name: "usuario",
        type: "select",
        value: "",
      },
    ];

    var _ini =  this.state.filterConfig.FROMDATE.value;
    var _end = this.state.filterConfig.TODATE.value;

    _ini.setDate(_ini.getDate() - 14);
    _end.setDate(_end.getDate() + 14);


    let datos = {
      components: components,
      filterDefinition: filterDefinition,
      filters: {
        INSERT_DATE: [
         _ini, _end
        ],
        AGENCIA: "",
        METODO_PAGO: "",
        TIPODEENVIO: "",
        usuario: "",
      },
    };

    let resp = await searchHandler(
      event,
      this.state.filterConfig,
      this.state.showMore,
      this.state.api_url + "/externalapi/execute/repOrdersHist/key/12345678"
    );

    if (resp.filterConfig) {
      this.setState({
        filterConfig: resp.filterConfig,
        showMore: resp.showMore,
      });
    }

    var excelrep1, excelrep2;

    if (resp.tableData) {
      let dataOrders = [];
      let url = API_CONFIG.xflowurl + "/components/get-component-report";
      await axios({
        url: url,
        headers: {
          "Access-Control-Allow-Origin": "*",
          origin: "x-requested-with",
          "Access-Control-Allow-Headers":
            "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        method: "post",
        data: datos,
      })
        .then((response) => response.data)
        .then((data) => {
          if (data.status) {
            dataOrders = data.results[0];
          }
        });
      console.log("status", resp.tableData.orderstatus);
      if (resp.tableData.orderstatus) {
        let updateStatus = "OK";
        if (
          resp.tableData.orderstatus[0].DIF_ORDENES > 0 ||
          resp.tableData.orderstatus[0].DIF_SKUS > 0
        ) {
          updateStatus =
            "Hubo un problema en la actualización del reporte consulte con el soporte, hay una diferencia de " +
            (resp.tableData.orderstatus[0].DIF_ORDENES +
              resp.tableData.orderstatus[0].DIF_SKUS) +
            " registros entre la ultima lectura online y los datos cargados";
        }

        this.setState({
          updateStatus: updateStatus,
          lastUdpate: resp.tableData.orderstatus[0].FECHA_LECTURA,
        });
      }
      let ordersR = [];
      if (resp.tableData.orders) {
        for (var r in resp.tableData.orders) {
          var row = resp.tableData.orders[r];

          // console.log(resp.tableData.orders[r]);

          row["EVENTNAME"] = "";
          row["EVENTTYPE"] = "";
          try {
            let info = JSON.parse(row.ADDRESSINFO);
            if (info.eventname) {
              row["EVENTNAME"] = info.eventname;
              row["EVENTTYPE"] = info.eventtype;
            }
          } catch (ex) {
            console.log("no json", row.ADDRESSINFO);
          }

          if (row["COUPONCODE"]) {
            let coupons = row["COUPONCODE"].split(",");
            let _couponcode = [];
            let _couponAdminCode = [];
            let _couponvalue = [];
            coupons.map(function (item) {
              let _coupondata = item.split("_");
              let _couponInfo = _coupondata[0].split("|");
              let couponAdminCode = _couponInfo[0];
              let couponCode = _couponInfo[1];

              if (_couponcode.indexOf(couponCode) < 0) {
                _couponcode.push(couponCode);
              }
              if (_couponAdminCode.indexOf(couponAdminCode) < 0) {
                _couponAdminCode.push(couponAdminCode);
              }

              if (_coupondata[1]) {
                _couponvalue.push(_coupondata[1]);
              }
            });
            row["COUPONCODE"] = _couponcode.toString();
            row["COUPONADMINCODE"] = _couponAdminCode.toString();
            row["COUPONVALUE"] = _couponvalue.toString();
          }
          if (row["HAVECOUPON"]) {
            let coupons = row["HAVECOUPON"].split(",");
            let _couponnames = [];
            coupons.map(function (item) {
              if (_couponnames.indexOf(item) < 0) {
                _couponnames.push(item);
              }
            });
            row["HAVECOUPON"] = _couponnames.toString();
          }

          this.state.defCols.map(function (item) {
            if (!item.enabled) {
              delete row[item.value];
            }
          });
          let isPickUp;
          let pos = find(dataOrders, row.ORDERID, "ID_PEDIDO");

          if (pos >= 0) {
            try {
              row["SKUS"] = JSON.parse(dataOrders[pos].SKUS) || [];
            } catch (e) {
              row["SKUS"] = [];
            }

            //            console.log(row["SKUS"]);
            if (dataOrders[pos].ESTADO_PICKUP) {
              if (
                dataOrders[pos].ESTADO_PICKUP !== "" &&
                dataOrders[pos].ESTADO_PICKUP !== "finalizado"
              ) {
                isPickUp = true;
              }
            }
          }
          //console.log("!!!!!!!!row", row, isPickUp);
          if (row["STATUSID"] === 5) {
            isPickUp = false;
          }

          if (isPickUp) {
             console.log("row si", row, dataOrders[pos]);
            let rowT = { ...row };
            let _ESTADO_PICKUP = dataOrders[pos].ESTADO_PICKUP;

            if (_ESTADO_PICKUP.indexOf("todo") >= 0) {
              console.log("todo")
              isPickUp = false;
              _ESTADO_PICKUP = _ESTADO_PICKUP.replace("todo", "");

              if (row["STATUSID"] !== 6 && row["STATUSID"] !== 5) {
                row["STATUSNAME"] = "En espera";

                if (row["STATUSID"] === 10) {
                  row["STATUSNAME"] = row["STATUSNAME"] + "(parcialmente)";
                }
              }


              let estado_pickup = "";
              console.log("ESTADO_PICKUP", _ESTADO_PICKUP)
              switch (_ESTADO_PICKUP) {
                case "procesando":
                  estado_pickup = "En espera de traspaso";
                  //OLD estado_pickup = "Procesando Traspaso";

                  break;
                case "recepcion":
                  estado_pickup = "Recepcionando traspaso";
                  //OLD estado_pickup = "Pedido recepcionado";
                  break;
                case "recepcionado":
                  estado_pickup = "Picking productos recibidos";
                  break;
                case "finalizado":
                  estado_pickup = "Estado Finalizado";

                  break;
                default:
                  estado_pickup = dataOrders[pos].ESTADO_PICKUP;
              }

              if (dataOrders[pos].ESTADO==="PENDIENTE" && row["STATUSID"] ===6 ){
                console.log("pendiente!!!")
                estado_pickup= "Listo para Entrega en Tienda"
              }

              row["STATUSNAME"] = estado_pickup;
              
            } else {
              if (row["STATUSID"] === 10) {
                row["STATUSNAME"] = row["STATUSNAME"] + "(parcialmente)";
              }
            }

            let estado_pickup = "";
            //console.log("ESTADO_PICKUP", ESTADO_PICKUP);
            switch (_ESTADO_PICKUP) {
              case "procesando":
                estado_pickup = "En espera de traspaso";
                //OLD estado_pickup = "Procesando Traspaso";

                break;
              case "recepcion":
                estado_pickup = "Recepcionando traspaso";
                //OLD estado_pickup = "Pedido recepcionado";
                break;
              case "recepcionado":
                estado_pickup = "Picking productos recibidos";
                break;
              case "finalizado":
                estado_pickup = "Estado Finalizado";

                break;
              default:
                estado_pickup = dataOrders[pos].ESTADO_PICKUP;
            }

            row["REALSTORE"] = resp.tableData.orders[r].AGENCIA;
            row["STORENAME"] = dataOrders[pos].AGENCIA;

            row["NOTA_SAP"] = dataOrders[pos].NOTA_SAP;

            ordersR.push(row);
            if (row.STATUSNAME === "Cancelado") {
              estado_pickup = "Cancelado";
            }

            if (isPickUp) {
              rowT["STATUSNAME"] = estado_pickup;
              rowT["ORDERID"] = row["ORDERID"] + "-p";
              if (dataOrders[pos].AGENCIA === "Sucursal La Paz Multicine") {
                rowT["STORENAME"] = "Sucursal La Paz Megacenter";
                rowT["REALSTORE"] = "Sucursal La Paz Megacenter";
              } else if (
                dataOrders[pos].AGENCIA === "Sucursal Santa Cruz Ventura"
              ) {
                rowT["STORENAME"] = "Sucursal Santa Cruz Banzer";
                rowT["REALSTORE"] = "Sucursal Santa Cruz Banzer";
              }

              ordersR.push(rowT);
            }
          } else {
            console.log("cancelado!", row , dataOrders[pos])
            if (pos >= 0) {
              row["STORENAME"] = dataOrders[pos].AGENCIA;
              row["REALSTORE"] = dataOrders[pos].AGENCIA;
              ordersR.push(row);
            } else {
              //console.log("row no", row);
              ordersR.push(row);
            }
          }

          if (row["STATUSID"] === 19) {
            //console.log("cancelado")
            row["STATUSNAME"] = "Cancelado";
          }
          
        }
      }

      const tableData = this.state.tableData;
      tableData.rows = ordersR; //resp.tableData.orders ? resp.tableData.orders : [];

      //      console.log("ordersR", ordersR);
      //console.log("skus original", resp.tableData.orderskus);
      let ordersSKUs = [];
      if (resp.tableData.orderskus) {
        for (var r in resp.tableData.orderskus) {
          let row = resp.tableData.orderskus[r];
          let _couponcode;
          let _couponvalue;

          this.state.defCols.map(function (item) {
            if (!item.enabled) {
              delete row[item.value];
            }
          });

          //console.log("row", row);

          let filteredItems = tableData.rows.filter(
            (item) => item.ORDERID === row.ORDERID
          );

          let matchingItem = filteredItems[0];

          if (matchingItem) {
            // Actualiza las propiedades del objeto row si matchingItem está definido
            row["COUPONCODE"] = matchingItem.COUPONCODE || "";
            row["COUPONADMINCODE"] = matchingItem.COUPONADMINCODE || "";
            row["COUPONVALUE"] = matchingItem.COUPONVALUE || "";
            row["HAVECOUPON"] = matchingItem.HAVECOUPON || "";
            row["STATUSNAME"] = matchingItem.STATUSNAME;
            row["SKU_REALSTORE"] = matchingItem["STORENAME"];
            // console.log("si", row, matchingItem);
            row["STORENAME"] = matchingItem.STORENAME || row["STORENAME"];

            if (matchingItem.SKUS) {
              //console.log("mmm", matchingItem.SKUS, row);
              if (matchingItem.SKUS.length > 0) {
                let skuPos = matchingItem.SKUS.filter(
                  (item) => item.sku === row.SKU
                );
                if (skuPos.length > 0) {
                  let storeCode = skuPos[0].store;
                  //console.log(this.state.stores, storeCode);
                  let storeObj = this.state.stores.filter(
                    (item) => item.code === storeCode
                  );
                  let fixedStore = storeObj[0].store;
                  row["STORENAME"] = fixedStore;
                }
              }
            }
          }

          /*
          tableData.rows.map(function (item) {  
            if (item.ORDERID == row.ORDERID) {
              //						 	console.log("es su orden ")
              row["COUPONCODE"] = item.COUPONCODE ? item.COUPONCODE : "";
              row["COUPONADMINCODE"] = item.COUPONADMINCODE
                ? item.COUPONADMINCODE
                : "";
              row["COUPONVALUE"] = item.COUPONVALUE ? item.COUPONVALUE : "";
              row["HAVECOUPON"] = item.HAVECOUPON ? item.HAVECOUPON : "";
              row["STATUSNAME"] = item.STATUSNAME;
              row["STORENAME"] = item.STORENAME
                ? item.STORENAME
                : row["STORENAME"];
            }


             
            if (item.ORDERID == row.ORDERID + "-p") {
              console.log("es extra");
              let rowT = { ...row };
              rowT["COUPONCODE"] = item.COUPONCODE ? item.COUPONCODE : "";
              rowT["COUPONADMINCODE"] = item.COUPONADMINCODE
                ? item.COUPONADMINCODE
                : "";
              rowT["COUPONVALUE"] = item.COUPONVALUE ? item.COUPONVALUE : "";
              rowT["HAVECOUPON"] = item.HAVECOUPON ? item.HAVECOUPON : "";

              rowT["STORENAME"] = item.STORENAME
                ? item.STORENAME
                : row["STORENAME"];

              rowT["STATUSNAME"] = item.STATUSNAME;
              rowT["ORDERID"] = row["ORDERID"] + "-p";

              ordersSKUs.push(rowT);
            }
            
          });
          */

          ordersSKUs.push(row);
        }
      }

      const tableData2 = this.state.tableData2;
      tableData2.rows = ordersSKUs;

      var _columns = [];
      var _defCols = [];
      this.state.defCols.map(function (item) {
        if (item.enabled && item.value.indexOf("SKU") < 0) {
          _columns.push({
            dataIndex: item.value,
            title: item.title,
            width: item.width,
          });
          _defCols.push(item);
        }
      });
      tableData.columns = _columns;

      excelrep1 = (
        <ExcelSheet data={/*resp.tableData.orders*/ ordersR} name="Pedidos">
          {_defCols.map((item, index) => (
            <ExcelColumn label={item.title} value={item.value} />
          ))}
        </ExcelSheet>
      );
      //console.log(excelrep1)

      _defCols = [];
      _columns = [];
      this.state.defCols.map(function (item) {
        if (item.enabled) {
          _columns.push({
            dataIndex: item.value,
            title: item.title === "Tienda" ? "Tienda Stock" : item.title,
            width: item.width,
          });
          _defCols.push(item);
        }
      });
      tableData2.columns = _columns;

      excelrep2 = (
        <ExcelSheet data={resp.tableData.orderskus} name="Pedidos SKUs">
          {_defCols.map((item, index) => (
            <ExcelColumn
              label={item.title === "Tienda" ? "Tienda Stock" : item.title}
              value={item.value}
            />
          ))}
        </ExcelSheet>
      );
      //console.log(excelrep2)

      window.location.hash = "#results";
    }

    this.setState({
      isLoading: false,
      excelrep1: excelrep1,
      excelrep2: excelrep2,
    });
  };

  selectItem = (item) => {
    if (item != "ORDERID" && item != "SKU") {
      var defCols = this.state.defCols;
      defCols.map(function (row) {
        if (row.value == item) {
          row["enabled"] = !row["enabled"];
        }
      });
      this.setState({ defCols: defCols });
    }
  };

  selectAllItems = (item) => {
    var defCols = this.state.defCols;
    defCols.map(function (row) {
      if (row.value != "ORDERID" && row.value != "SKU") {
        row["enabled"] = true;
      }
    });
    this.setState({ defCols: defCols, isChecked: true });
  };

  removeAllItem = (item) => {
    var defCols = this.state.defCols;
    defCols.map(function (row) {
      if (row.value != "ORDERID" && row.value != "SKU") {
        row["enabled"] = false;
      }
    });
    this.setState({ defCols: defCols, isChecked: false });
  };

  render() {
    return (
      <>
        <PageLoading isLoading={this.state.isLoading} />

        <MDBModal
          cascading
          isOpen={this.state.modal1}
          toggle={this.toggleModal(1)}
          size="lg"
        >
          <MDBModalBody className="text-center">
            <span className="h4-responsive text-center text-uppercase">
              <b> Campos a Exportar </b>{" "}
            </span>
            <hr />

            <MDBRow className="text-left">
              {this.state.defCols.map((row, index) => (
                <MDBCol key={"col" + index} md="4">
                  <MDBInput
                    size="sm"
                    onClick={() => {
                      this.selectItem(row.value);
                    }}
                    key={"check" + row.value}
                    className="nomargin"
                    name={"check" + row.value}
                    label={row.title}
                    filled
                    checked={row.enabled}
                    type="checkbox"
                    id={"check" + row.value}
                  />
                </MDBCol>
              ))}
            </MDBRow>
            <hr />
            <MDBRow className="text-right">
              <MDBCol sm="12">
                {this.state.isChecked ? (
                  <button
                    className="no-button"
                    color="secondary"
                    size="sm"
                    onClick={this.removeAllItem}
                  >
                    {" "}
                    <MDBIcon far icon="square" /> Desmarcar Todos
                  </button>
                ) : (
                  <button
                    className="no-button"
                    color="secondary"
                    size="sm"
                    onClick={this.selectAllItems}
                  >
                    {" "}
                    <MDBIcon icon="check-square" />
                    Marcar Todos
                  </button>
                )}
              </MDBCol>
            </MDBRow>

            <MDBRow className="text-center">
              <MDBCol sm="12">
                <MDBBtn
                  color="dark"
                  size="sm"
                  onClick={() => {
                    this.setState({ modal1: false });
                  }}
                >
                  Cerrar
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>

        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="row">
              <div className="col-12 col-md-8 col-lg-4">
                <h2 className={"h2-responsive text-left"}>
                  {" "}
                  Export Orders Histórico
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-8 col-lg-4 col-xl-2">
                <Filters
                  filterConfig={this.state.filterConfig}
                  showMore={this.state.showMore}
                  searchHandler={this.searchHandler}
                />

                <div className="row mt-2">
                  <div className="col-12">
                    <div className="col-12">
                      <button
                        type="button"
                        className="btn  btn-sm  btn-dark btn-block"
                        name="setFields"
                        onClick={() => {
                          this.setState({ modal1: true });
                        }}
                      >
                        {" "}
                        Campos a Exportar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-12 col-xl-10">
                <div
                  id="results"
                  className="col-12 col-lg-12 text-right mb-2"
                  style={{ padding: 0 }}
                >
                  <ExcelFile
                    filename={"export-orders." + this.state.date_label}
                    element={
                      <button
                        disabled={this.state.tableData.rows.length == 0}
                        type="button"
                        className="btn btn-sm btn-success"
                        name="set"
                      >
                        {" "}
                        Exportar
                      </button>
                    }
                  >
                    {this.state.excelrep1}
                    {this.state.excelrep2}
                  </ExcelFile>
                </div>

                <div className="col-12 col-lg-12" style={{ padding: 0 }}>
                  <div className="card card-table flex-fill">
                    <h4 className="p-1"> Pedidos </h4>
                    {this.state.lastUdpate && (
                      <h6 className="p-1">
                        Fecha de última actualización del reporte:{" "}
                        <b>{this.state.lastUdpate} </b>
                      </h6>
                    )}
                    {this.state.updateStatus !== "OK" && (
                      <h6
                        style={{ background: "#ffa700", fontWeight: "bold" }}
                        className="p-1"
                      >
                        ADVERTENCIA: {this.state.updateStatus}{" "}
                      </h6>
                    )}

                    <Table
                      tableData={this.state.tableData}
                      scroll={{ x: 1650, y: 300 }}
                    />
                  </div>

                  <div className="card card-table flex-fill">
                    <h4 className="p-1"> Pedidos SKUs </h4>
                    <Table
                      tableData={this.state.tableData2}
                      scroll={{ x: 1650, y: 300 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default page;
